@font-face {
	font-family: 'Lato';
	src: url('../fonts/lato/lato-black.eot');
	src: url('../fonts/lato/lato-black.eot?#iefix') format('embedded-opentype'), url('../fonts/lato/lato-black.woff') format('woff'), url('../fonts/lato/lato-black.ttf') format('truetype'), url('../fonts/lato/lato-black.svg#latoblack') format('svg');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Lato';
	src: url('../fonts/lato/lato-bold.eot');
	src: url('../fonts/lato/lato-bold.eot?#iefix') format('embedded-opentype'), url('../fonts/lato/lato-bold.woff') format('woff'), url('../fonts/lato/lato-bold.ttf') format('truetype'), url('../fonts/lato/lato-bold.svg#latobold') format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Lato';
	src: url('../fonts/lato/lato-bolditalic.eot');
	src: url('../fonts/lato/lato-bolditalic.eot?#iefix') format('embedded-opentype'), url('../fonts/lato/lato-bolditalic.woff') format('woff'), url('../fonts/lato/lato-bolditalic.ttf') format('truetype'), url('../fonts/lato/lato-bolditalic.svg#latobold-italic') format('svg');
	font-weight: bold;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Lato';
	src: url('../fonts/lato/lato-italic.eot');
	src: url('../fonts/lato/lato-italic.eot?#iefix') format('embedded-opentype'), url('../fonts/lato/lato-italic.woff') format('woff'), url('../fonts/lato/lato-italic.ttf') format('truetype'), url('../fonts/lato/lato-italic.svg#latoitalic') format('svg');
	font-weight: normal;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Lato';
	src: url('../fonts/lato/lato-light.eot');
	src: url('../fonts/lato/lato-light.eot?#iefix') format('embedded-opentype'), url('../fonts/lato/lato-light.woff') format('woff'), url('../fonts/lato/lato-light.ttf') format('truetype'), url('../fonts/lato/lato-light.svg#latolight') format('svg');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Lato';
	src: url('../fonts/lato/lato-regular.eot');
	src: url('../fonts/lato/lato-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/lato/lato-regular.woff') format('woff'), url('../fonts/lato/lato-regular.ttf') format('truetype'), url('../fonts/lato/lato-regular.svg#latoregular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

/* latin */
@font-face {
	font-family: 'Arvo';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/arvo/tDbD2oWUg0MKqScQ7Q.woff2') format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
	font-family: 'Arvo';
	font-style: normal;
	font-weight: 700;
	src: url('../fonts/arvo/tDbM2oWUg0MKoZw1-LPK8w.woff2') format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: "Flat-UI-Icons";
	src: url('../fonts/flat-ui-icons-regular.eot');
	src: url('../fonts/flat-ui-icons-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/flat-ui-icons-regular.woff') format('woff'), url('../fonts/flat-ui-icons-regular.ttf') format('truetype'), url('../fonts/flat-ui-icons-regular.svg#flat-ui-icons-regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

/* Use the following CSS code if you want to use data attributes for inserting your icons */
[data-icon]:before {
	font-family: 'Flat-UI-Icons';
	content: attr(data-icon);
	speak: none;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* Use the following CSS code if you want to have a class per icon */
/*
Instead of a list of all class selectors,
you can use the generic selector below, but it's slower:
[class*="fui-"] {
*/
.fui-arrow-right,
.fui-arrow-left,
.fui-cmd,
.fui-check-inverted,
.fui-heart,
.fui-location,
.fui-plus,
.fui-check,
.fui-cross,
.fui-list,
.fui-new,
.fui-video,
.fui-photo,
.fui-volume,
.fui-time,
.fui-eye,
.fui-chat,
.fui-search,
.fui-user,
.fui-mail,
.fui-lock,
.fui-gear,
.fui-radio-unchecked,
.fui-radio-checked,
.fui-checkbox-unchecked,
.fui-checkbox-checked,
.fui-calendar-solid,
.fui-pause,
.fui-play,
.fui-check-inverted-2 {
	display: inline-block;
	font-family: 'Flat-UI-Icons';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.fui-arrow-right:before {
	content: "\e02c";
}

.fui-arrow-left:before {
	content: "\e02d";
}

.fui-cmd:before {
	content: "\e02f";
}

.fui-check-inverted:before {
	content: "\e006";
}

.fui-heart:before {
	content: "\e007";
}

.fui-location:before {
	content: "\e008";
}

.fui-plus:before {
	content: "\e009";
}

.fui-check:before {
	content: "\e00a";
}

.fui-cross:before {
	content: "\e00b";
}

.fui-list:before {
	content: "\e00c";
}

.fui-new:before {
	content: "\e00d";
}

.fui-video:before {
	content: "\e00e";
}

.fui-photo:before {
	content: "\e00f";
}

.fui-volume:before {
	content: "\e010";
}

.fui-time:before {
	content: "\e011";
}

.fui-eye:before {
	content: "\e012";
}

.fui-chat:before {
	content: "\e013";
}

.fui-search:before {
	content: "\e01c";
}

.fui-user:before {
	content: "\e01d";
}

.fui-mail:before {
	content: "\e01e";
}

.fui-lock:before {
	content: "\e01f";
}

.fui-gear:before {
	content: "\e024";
}

.fui-radio-unchecked:before {
	content: "\e02b";
}

.fui-radio-checked:before {
	content: "\e032";
}

.fui-checkbox-unchecked:before {
	content: "\e033";
}

.fui-checkbox-checked:before {
	content: "\e034";
}

.fui-calendar-solid:before {
	content: "\e022";
}

.fui-pause:before {
	content: "\e03b";
}

.fui-play:before {
	content: "\e03c";
}

.fui-check-inverted-2:before {
	content: "\e000";
}

.dropdown-arrow-inverse {
	border-bottom-color: #34495e !important;
	border-top-color: #34495e !important;
}

body {
	height: 100%;
	width: 100%;
	font-family: "Lato", Helvetica, Arial, sans-serif;
	font-size: 16px;
	line-height: 1.72222;
	color: #34495e;
	background-color: #ffffff;
}

a {
	color: #34495e;
	text-decoration: none;
	-webkit-transition: 0.25s;
	transition: 0.25s;
}

a:hover,
a:focus {
	color: #34495e;
	text-decoration: none;
}

a:focus {
	outline: none;
}

.img-rounded {
	border-radius: 6px;
}

.img-thumbnail {
	padding: 4px;
	line-height: 1.72222;
	background-color: #ffffff;
	border: 2px solid #bdc3c7;
	border-radius: 6px;
	-webkit-transition: all 0.25s ease-in-out;
	transition: all 0.25s ease-in-out;
	display: inline-block;
	max-width: 100%;
	height: auto;
}

.img-comment {
	font-size: 15px;
	line-height: 1.2;
	font-style: italic;
	margin: 24px 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	font-family: inherit;
	font-weight: 700;
	line-height: 1.1;
	color: inherit;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small {
	color: #e7e9ec;
}

h1,
h2,
h3 {
	margin-top: 30px;
	margin-bottom: 5px;
}

h4,
h5,
h6 {
	margin-top: 15px;
	margin-bottom: 15px;
}

h6 {
	font-weight: normal;
}

h1,
.h1 {
	font-size: 80px;
}

h2,
.h2 {
	font-size: 63px;
}

h3,
.h3 {
	font-size: 30px;
}

h4,
.h4 {
	font-size: 20px;
}

p {
	font-size: 16px;
	line-height: 1.72222;
	margin: 0 0 15px;
}

li {
	list-style-type: none
}

.white {
	color: white
}

.hidden {
	visibility: hidden;
}

.lead {
	margin-bottom: 30px;
	margin-top: 20px;
	font-size: 20px;
	line-height: 1.46428571;
	font-weight: 300;
}

@media (min-width: 768px) {
	.lead {

	}
}

small,
.small {
	font-size: 83%;
	line-height: 2.067;
}

.text-muted {
	color: #bdc3c7;
}

.text-inverse {
	color: #ffffff;
}

.text-primary {
	color: #1abc9c;
}

a.text-primary:hover {
	color: #15967d;
}

.text-warning {
	color: #f1c40f;
}

a.text-warning:hover {
	color: #c19d0c;
}

.text-danger {
	color: #e74c3c;
}

a.text-danger:hover {
	color: #b93d30;
}

.text-success {
	color: #2ecc71;
}

a.text-success:hover {
	color: #25a35a;
}

.text-info {
	color: #3498db;
}

a.text-info:hover {
	color: #2a7aaf;
}

.bg-primary {
	color: #ffffff;
	background-color: #34495e;
}

a.bg-primary:hover {
	background-color: #222f3d;
}

.bg-success {
	background-color: #dff0d8;
}

a.bg-success:hover {
	background-color: #c1e2b3;
}

.bg-info {
	background-color: #d9edf7;
}

a.bg-info:hover {
	background-color: #afd9ee;
}

.bg-warning {
	background-color: #fcf8e3;
}

a.bg-warning:hover {
	background-color: #f7ecb5;
}

.bg-danger {
	background-color: #f2dede;
}

a.bg-danger:hover {
	background-color: #e4b9b9;
}

.page-header {
	padding-bottom: 14px;
	margin: 60px 0 30px;
	border-bottom: 1px solid #e7e9ec;
}

ul,
ol {
	margin-bottom: 15px;
}

dl {
	margin-bottom: 30px;
}

dt,
dd {
	line-height: 1.72222;
}

@media (min-width: 768px) {
	.dl-horizontal dt {
		width: 160px;
	}

	.dl-horizontal dd {
		margin-left: 180px;
	}
}

abbr[title],
abbr[data-original-title] {
	border-bottom: 1px dotted #bdc3c7;
}

blockquote {
	border-left: 3px solid #e7e9ec;
	padding: 0 0 0 16px;
	margin: 0 0 30px;
}

blockquote p {
	font-size: 20px;
	line-height: 1.55;
	font-weight: normal;
	margin-bottom: .4em;
}

blockquote small,
blockquote .small {
	font-size: 18px;
	line-height: 1.72222;
	font-style: italic;
	color: inherit;
}

blockquote small:before,
blockquote .small:before {
	content: "";
}

blockquote.pull-right {
	padding-right: 16px;
	padding-left: 0;
	border-right: 3px solid #e7e9ec;
	border-left: 0;
}

blockquote.pull-right small:after {
	content: "";
}

address {
	margin-bottom: 30px;
	line-height: 1.72222;
}

code,
kbd,
pre,
samp {
	font-family: Monaco, Menlo, Consolas, "Courier New", monospace;
}

code {
	padding: 2px 6px;
	font-size: 85%;
	color: #c7254e;
	background-color: #f9f2f4;
	border-radius: 4px;
}

kbd {
	padding: 2px 6px;
	font-size: 85%;
	color: #ffffff;
	background-color: #34495e;
	border-radius: 4px;
	box-shadow: none;
}

pre {
	padding: 8px;
	margin: 0 0 15px;
	font-size: 13px;
	line-height: 1.72222;
	color: inherit;
	background-color: #ffffff;
	border: 2px solid #e7e9ec;
	border-radius: 6px;
	white-space: pre;
}

.pre-scrollable {
	max-height: 340px;
}

.thumbnail {
	display: block;
	padding: 4px;
	margin-bottom: 5px;
	line-height: 1.72222;
	background-color: #ffffff;
	border: 2px solid #bdc3c7;
	border-radius: 6px;
	-webkit-transition: all 0.25s ease-in-out;
	transition: all 0.25s ease-in-out;
}

.thumbnail > img,
.thumbnail a > img {
	display: block;
	max-width: 100%;
	height: auto;
	margin-left: auto;
	margin-right: auto;
}

a.thumbnail:hover,
a.thumbnail:focus,
a.thumbnail.active {
	border-color: #16a085;
}

.thumbnail .caption {
	padding: 9px;
	color: #34495e;
}

.btn {

	font-size: 15px;
	font-weight: normal;
	line-height: 1.4;
	border-radius: 4px;
	padding: 10px 15px;
	-webkit-font-smoothing: subpixel-antialiased;
	-webkit-transition: border .25s linear, color .25s linear, background-color .25s linear;
	transition: border .25s linear, color .25s linear, background-color .25s linear;
}

.btn:hover,
.btn:focus {
	outline: none;
	color: #ffffff;
}

.btn:active,
.btn.active {
	outline: none;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
	background-color: #bdc3c7;
	color: rgba(255, 255, 255, 0.75);
	opacity: 0.7;
	filter: alpha(opacity=70);
}

.btn > [class^="fui-"] {
	margin: 0 1px;
	position: relative;
	line-height: 1;
	top: 1px;
}

.btn-xs.btn > [class^="fui-"] {
	font-size: 11px;
	top: 0;
}

.btn-hg.btn > [class^="fui-"] {
	top: 2px;
}

.btn-default {
	color: #ffffff;
	background-color: #bdc3c7;
}

.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
	color: #ffffff;
	background-color: #cacfd2;
	border-color: #cacfd2;
}

.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
	background: #a1a6a9;
	border-color: #a1a6a9;
}

.btn-default.disabled,
.btn-default[disabled],
fieldset[disabled] .btn-default,
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled:active,
.btn-default[disabled]:active,
fieldset[disabled] .btn-default:active,
.btn-default.disabled.active,
.btn-default[disabled].active,
fieldset[disabled] .btn-default.active {
	background-color: #bdc3c7;
	border-color: #bdc3c7;
}

.btn-primary {
	color: #ffffff;
	background-color: #1abc9c;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
	color: #ffffff;
	background-color: #48c9b0;
	border-color: #48c9b0;
}

.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
	background: #16a085;
	border-color: #16a085;
}

.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
	background-color: #1abc9c;
	border-color: #1abc9c;
}

.btn-info {
	color: #ffffff;
	background-color: #3498db;
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.open .dropdown-toggle.btn-info {
	color: #ffffff;
	background-color: #5dade2;
	border-color: #5dade2;
}

.btn-info:active,
.btn-info.active,
.open .dropdown-toggle.btn-info {
	background: #2c81ba;
	border-color: #2c81ba;
}

.btn-info.disabled,
.btn-info[disabled],
fieldset[disabled] .btn-info,
.btn-info.disabled:hover,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info:hover,
.btn-info.disabled:focus,
.btn-info[disabled]:focus,
fieldset[disabled] .btn-info:focus,
.btn-info.disabled:active,
.btn-info[disabled]:active,
fieldset[disabled] .btn-info:active,
.btn-info.disabled.active,
.btn-info[disabled].active,
fieldset[disabled] .btn-info.active {
	background-color: #3498db;
	border-color: #3498db;
}

.btn-danger {
	color: #ffffff;
	background-color: #e74c3c;
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.open .dropdown-toggle.btn-danger {
	color: #ffffff;
	background-color: #ec7063;
	border-color: #ec7063;
}

.btn-danger:active,
.btn-danger.active,
.open .dropdown-toggle.btn-danger {
	background: #c44133;
	border-color: #c44133;
}

.btn-danger.disabled,
.btn-danger[disabled],
fieldset[disabled] .btn-danger,
.btn-danger.disabled:hover,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger:hover,
.btn-danger.disabled:focus,
.btn-danger[disabled]:focus,
fieldset[disabled] .btn-danger:focus,
.btn-danger.disabled:active,
.btn-danger[disabled]:active,
fieldset[disabled] .btn-danger:active,
.btn-danger.disabled.active,
.btn-danger[disabled].active,
fieldset[disabled] .btn-danger.active {
	background-color: #e74c3c;
	border-color: #e74c3c;
}

.btn-success {
	color: #ffffff;
	background-color: #2ecc71;
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.open .dropdown-toggle.btn-success {
	color: #ffffff;
	background-color: #58d68d;
	border-color: #58d68d;
}

.btn-success:active,
.btn-success.active,
.open .dropdown-toggle.btn-success {
	background: #27ad60;
	border-color: #27ad60;
}

.btn-success.disabled,
.btn-success[disabled],
fieldset[disabled] .btn-success,
.btn-success.disabled:hover,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success:hover,
.btn-success.disabled:focus,
.btn-success[disabled]:focus,
fieldset[disabled] .btn-success:focus,
.btn-success.disabled:active,
.btn-success[disabled]:active,
fieldset[disabled] .btn-success:active,
.btn-success.disabled.active,
.btn-success[disabled].active,
fieldset[disabled] .btn-success.active {
	background-color: #2ecc71;
	border-color: #2ecc71;
}

.btn-warning {
	color: #ffffff;
	background-color: #f1c40f;
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.open .dropdown-toggle.btn-warning {
	color: #ffffff;
	background-color: #f5d313;
	border-color: #f5d313;
}

.btn-warning:active,
.btn-warning.active,
.open .dropdown-toggle.btn-warning {
	background: #cda70d;
	border-color: #cda70d;
}

.btn-warning.disabled,
.btn-warning[disabled],
fieldset[disabled] .btn-warning,
.btn-warning.disabled:hover,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning:hover,
.btn-warning.disabled:focus,
.btn-warning[disabled]:focus,
fieldset[disabled] .btn-warning:focus,
.btn-warning.disabled:active,
.btn-warning[disabled]:active,
fieldset[disabled] .btn-warning:active,
.btn-warning.disabled.active,
.btn-warning[disabled].active,
fieldset[disabled] .btn-warning.active {
	background-color: #f1c40f;
	border-color: #f1c40f;
}

.btn-inverse {
	color: #ffffff;
	background-color: #34495e;
}

.btn-inverse:hover,
.btn-inverse:focus,
.btn-inverse:active,
.btn-inverse.active,
.open .dropdown-toggle.btn-inverse {
	color: #ffffff;
	background-color: #415b76;
	border-color: #415b76;
}

.btn-inverse:active,
.btn-inverse.active,
.open .dropdown-toggle.btn-inverse {
	background: #34495e;
	border-color: #34495e;
}

.btn-inverse.disabled,
.btn-inverse[disabled],
fieldset[disabled] .btn-inverse,
.btn-inverse.disabled:hover,
.btn-inverse[disabled]:hover,
fieldset[disabled] .btn-inverse:hover,
.btn-inverse.disabled:focus,
.btn-inverse[disabled]:focus,
fieldset[disabled] .btn-inverse:focus,
.btn-inverse.disabled:active,
.btn-inverse[disabled]:active,
fieldset[disabled] .btn-inverse:active,
.btn-inverse.disabled.active,
.btn-inverse[disabled].active,
fieldset[disabled] .btn-inverse.active {
	background-color: #34495e;
	border-color: #34495e;
}

.btn-embossed {
	-webkit-box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.15);
	box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.15);
}

.btn-embossed.active,
.btn-embossed:active {
	-webkit-box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.15);
	box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.15);
}

.btn-wide {
	min-width: 140px;
	padding-left: 30px;
	padding-right: 30px;
}

.btn-link {
	color: #16a085;
}

.btn-link:hover,
.btn-link:focus {
	color: #1abc9c;
	text-decoration: underline;
	background-color: transparent;
}

.btn-link[disabled]:hover,
fieldset[disabled] .btn-link:hover,
.btn-link[disabled]:focus,
fieldset[disabled] .btn-link:focus {
	color: #bdc3c7;
	text-decoration: none;
}

.btn-hg {
	padding: 13px 20px;
	font-size: 22px;
	line-height: 1.227;
	border-radius: 6px;
}

.btn-lg {
	padding: 10px 19px;
	font-size: 17px;
	line-height: 1.471;
	border-radius: 6px;
}

.btn-sm {
	padding: 9px 13px;
	font-size: 13px;
	line-height: 1.385;
	border-radius: 4px;
}

.btn-xs {
	padding: 6px 9px;
	font-size: 12px;
	line-height: 1.083;
	border-radius: 3px;
}

.btn-tip {
	font-weight: 300;
	padding-left: 10px;
	font-size: 92%;
}

.btn-block {
	white-space: normal;
}

.btn-default .caret {
	border-top-color: #ffffff;
}

.btn-primary .caret,
.btn-success .caret,
.btn-warning .caret,
.btn-danger .caret,
.btn-info .caret {
	border-top-color: #ffffff;
}

.dropup .btn-default .caret {
	border-bottom-color: #ffffff;
}

.dropup .btn-primary .caret,
.dropup .btn-success .caret,
.dropup .btn-warning .caret,
.dropup .btn-danger .caret,
.dropup .btn-info .caret {
	border-bottom-color: #ffffff;
}

.btn-group-xs > .btn {
	padding: 6px 9px;
	font-size: 12px;
	line-height: 1.083;
	border-radius: 3px;
}

.btn-group-sm > .btn {
	padding: 9px 13px;
	font-size: 13px;
	line-height: 1.385;
	border-radius: 4px;
}

.btn-group-lg > .btn {
	padding: 10px 19px;
	font-size: 17px;
	line-height: 1.471;
	border-radius: 6px;
}

.btn-group-gh > .btn {
	padding: 13px 20px;
	font-size: 22px;
	line-height: 1.227;
	border-radius: 6px;
}

.btn-group > .btn + .btn {
	margin-left: 0;
}

.btn-group > .btn + .dropdown-toggle {
	border-left: 2px solid rgba(52, 73, 94, 0.15);
	padding-left: 12px;
	padding-right: 12px;
}

.btn-group > .btn + .dropdown-toggle .caret {
	margin-left: 3px;
	margin-right: 3px;
}

.btn-group > .btn.btn-gh + .dropdown-toggle .caret {
	margin-left: 7px;
	margin-right: 7px;
}

.btn-group > .btn.btn-sm + .dropdown-toggle .caret {
	margin-left: 0;
	margin-right: 0;
}

.dropdown-toggle .caret {
	margin-left: 8px;
}

.btn-group > .btn,
.btn-group > .dropdown-menu,
.btn-group > .popover {
	font-weight: 400;
}

.btn-group:focus .dropdown-toggle {
	outline: none;
	-webkit-transition: 0.25s;
	transition: 0.25s;
}

.btn-group.open .dropdown-toggle {
	color: rgba(255, 255, 255, 0.75);
	-webkit-box-shadow: none;
	box-shadow: none;
}

.btn-toolbar .btn.active {
	color: #ffffff;
}

.btn-toolbar .btn > [class^="fui-"] {
	font-size: 16px;
	margin: 0 1px;
}

.caret {
	border-width: 8px 6px;
	border-bottom-color: #34495e;
	border-top-color: #34495e;
	border-style: solid;
	border-bottom-style: none;
	-webkit-transition: 0.25s;
	transition: 0.25s;
	-webkit-transform: scale(1.001);
	-ms-transform: scale(1.001);
	transform: scale(1.001);
}

.dropup .caret,
.dropup .btn-lg .caret,
.navbar-fixed-bottom .dropdown .caret {
	border-bottom-width: 8px;
}

.btn-lg .caret {
	border-top-width: 8px;
	border-right-width: 6px;
	border-left-width: 6px;
}

.select {
	display: inline-block;
	margin-bottom: 10px;
}

[class*="span"] > .select[class*="span"] {
	margin-left: 0;
}

.select[class*="span"] .btn {
	width: 100%;
}

.select.select-block {
	display: block;
	float: none;
	margin-left: 0;
	width: auto;
}

.select.select-block:before,
.select.select-block:after {
	content: " ";
	/* 1 */

	display: table;
	/* 2 */

}

.select.select-block:after {
	clear: both;
}

.select.select-block .btn {
	width: 100%;
}

.select.select-block .dropdown-menu {
	width: 100%;
}

.select .btn {
	width: 220px;
}

.select .btn.btn-hg .filter-option {
	left: 20px;
	right: 40px;
	top: 13px;
}

.select .btn.btn-hg .caret {
	right: 20px;
}

.select .btn.btn-lg .filter-option {
	left: 18px;
	right: 38px;
}

.select .btn.btn-sm .filter-option {
	left: 13px;
	right: 33px;
}

.select .btn.btn-sm .caret {
	right: 13px;
}

.select .btn.btn-xs .filter-option {
	left: 13px;
	right: 33px;
	top: 5px;
}

.select .btn.btn-xs .caret {
	right: 13px;
}

.select .btn .filter-option {
	height: 26px;
	left: 13px;
	overflow: hidden;
	position: absolute;
	right: 33px;
	text-align: left;
	top: 10px;
}

.select .btn .caret {
	position: absolute;
	right: 16px;
	top: 50%;
	margin-top: -3px;
}

.select .btn .dropdown-toggle {
	border-radius: 6px;
}

.select .btn .dropdown-menu {
	min-width: 100%;
}

.select .btn .dropdown-menu dt {
	cursor: default;
	display: block;
	padding: 3px 20px;
}

.select .btn .dropdown-menu li:not(.disabled) > a:hover small {
	color: rgba(255, 255, 255, 0.004);
}

.select .btn .dropdown-menu li > a {
	min-height: 20px;
}

.select .btn .dropdown-menu li > a.opt {
	padding-left: 35px;
}

.select .btn .dropdown-menu li small {
	padding-left: .5em;
}

.select .btn .dropdown-menu li > dt small {
	font-weight: normal;
}

.select .btn > .disabled,
.select .btn .dropdown-menu li.disabled > a {
	cursor: default;
}

.select .caret {
	border-bottom-color: #ffffff;
	border-top-color: #ffffff;
}

legend {
	display: block;
	width: 100%;
	padding: 0;
	margin-bottom: 15px;
	font-size: 24px;
	line-height: inherit;
	color: inherit;
	border-bottom: none;
}

textarea {
	font-size: 20px;
	line-height: 24px;
	padding: 5px 11px;
}

input[type="search"] {
	-webkit-appearance: none !important;
}

label {
	font-weight: normal;
	font-size: 15px;
	line-height: 2.4;
}

.form-control:-moz-placeholder {
	color: #b2bcc5;
}

.form-control::-moz-placeholder {
	color: #b2bcc5;
	opacity: 1;
}

.form-control:-ms-input-placeholder {
	color: #b2bcc5;
}

.form-control::-webkit-input-placeholder {
	color: #b2bcc5;
}

.form-control.placeholder {
	color: #b2bcc5;
}

.form-control {
	border: 2px solid #bdc3c7;
	color: #34495e;
	font-family: "Lato", Helvetica, Arial, sans-serif;
	font-size: 15px;
	line-height: 1.467;
	padding: 8px 12px;
	height: 42px;
	-webkit-appearance: none;
	border-radius: 6px;
	-webkit-box-shadow: none;
	box-shadow: none;
	-webkit-transition: border .25s linear, color .25s linear, background-color .25s linear;
	transition: border .25s linear, color .25s linear, background-color .25s linear;
}

.form-group.focus .form-control,
.form-control:focus {
	border-color: #1abc9c;
	outline: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
	background-color: #f4f6f6;
	border-color: #d5dbdb;
	color: #d5dbdb;
	cursor: default;
	opacity: 0.7;
	filter: alpha(opacity=70);
}

.form-control.flat {
	border-color: transparent;
}

.form-control.flat:hover {
	border-color: #bdc3c7;
}

.form-control.flat:focus {
	border-color: #1abc9c;
}

.input-sm {
	height: 35px;
	padding: 6px 10px;
	font-size: 13px;
	line-height: 1.462;
	border-radius: 6px;
}

select.input-sm {
	height: 35px;
	line-height: 35px;
}

textarea.input-sm,
select[multiple].input-sm {
	height: auto;
}

.input-lg {
	height: 45px;
	padding: 10px 15px;
	font-size: 17px;
	line-height: 1.235;
	border-radius: 6px;
}

select.input-lg {
	height: 45px;
	line-height: 45px;
}

textarea.input-lg,
select[multiple].input-lg {
	height: auto;
}

.input-hg {
	height: 53px;
	padding: 10px 16px;
	font-size: 22px;
	line-height: 1.318;
	border-radius: 6px;
}

select.input-hg {
	height: 53px;
	line-height: 53px;
}

textarea.input-hg,
select[multiple].input-hg {
	height: auto;
}

.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline {
	color: #f1c40f;
}

.has-warning .form-control {
	color: #f1c40f;
	border-color: #f1c40f;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.has-warning .form-control:-moz-placeholder {
	color: #f1c40f;
}

.has-warning .form-control::-moz-placeholder {
	color: #f1c40f;
	opacity: 1;
}

.has-warning .form-control:-ms-input-placeholder {
	color: #f1c40f;
}

.has-warning .form-control::-webkit-input-placeholder {
	color: #f1c40f;
}

.has-warning .form-control.placeholder {
	color: #f1c40f;
}

.has-warning .form-control:focus {
	border-color: #f1c40f;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.has-warning .input-group-addon {
	color: #f1c40f;
	border-color: #f1c40f;
	background-color: #ffffff;
}

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline {
	color: #e74c3c;
}

.has-error .form-control {
	color: #e74c3c;
	border-color: #e74c3c;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.has-error .form-control:-moz-placeholder {
	color: #e74c3c;
}

.has-error .form-control::-moz-placeholder {
	color: #e74c3c;
	opacity: 1;
}

.has-error .form-control:-ms-input-placeholder {
	color: #e74c3c;
}

.has-error .form-control::-webkit-input-placeholder {
	color: #e74c3c;
}

.has-error .form-control.placeholder {
	color: #e74c3c;
}

.has-error .form-control:focus {
	border-color: #e74c3c;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.has-error .input-group-addon {
	color: #e74c3c;
	border-color: #e74c3c;
	background-color: #ffffff;
}

.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline {
	color: #2ecc71;
}

.has-success .form-control {
	color: #2ecc71;
	border-color: #2ecc71;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.has-success .form-control:-moz-placeholder {
	color: #2ecc71;
}

.has-success .form-control::-moz-placeholder {
	color: #2ecc71;
	opacity: 1;
}

.has-success .form-control:-ms-input-placeholder {
	color: #2ecc71;
}

.has-success .form-control::-webkit-input-placeholder {
	color: #2ecc71;
}

.has-success .form-control.placeholder {
	color: #2ecc71;
}

.has-success .form-control:focus {
	border-color: #2ecc71;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.has-success .input-group-addon {
	color: #2ecc71;
	border-color: #2ecc71;
	background-color: #ffffff;
}

.help-block {
	font-size: 15px;
	margin-bottom: 5px;
	color: inherit;
}

.form-group {
	position: relative;
	margin-bottom: 20px;
}

.form-horizontal .control-label,
.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
	margin-top: 0;
	margin-bottom: 0;
	padding-top: 6px;
}

.form-horizontal .form-group {
	margin-left: -15px;
	margin-right: -15px;
}

.form-horizontal .form-group:before,
.form-horizontal .form-group:after {
	content: " ";
	/* 1 */

	display: table;
	/* 2 */

}

.form-horizontal .form-group:after {
	clear: both;
}

.form-horizontal .form-control-static {
	padding-top: 6px;
}

.form-group {
	position: relative;
}

.form-control + .input-icon {
	position: absolute;
	top: 2px;
	right: 2px;
	line-height: 37px;
	vertical-align: middle;
	font-size: 20px;
	color: #b2bcc5;
	background-color: #ffffff;
	padding: 0 12px 0 0;
	border-radius: 6px;
}

.input-hg + .input-icon {
	line-height: 49px;
	padding: 0 16px 0 0;
}

.input-lg + .input-icon {
	line-height: 41px;
	padding: 0 15px 0 0;
}

.input-sm + .input-icon {
	font-size: 18px;
	line-height: 30px;
	padding: 0 10px 0 0;
}

.has-success .input-icon {
	color: #2ecc71;
}

.has-warning .input-icon {
	color: #f1c40f;
}

.has-error .input-icon {
	color: #e74c3c;
}

.form-control[disabled] + .input-icon,
.form-control[readonly] + .input-icon,
fieldset[disabled] .form-control + .input-icon,
.form-control.disabled + .input-icon {
	color: #d5dbdb;
	background-color: transparent;
	opacity: 0.7;
	filter: alpha(opacity=70);
}

.input-group-hg > .form-control,
.input-group-hg > .input-group-addon,
.input-group-hg > .input-group-btn > .btn {
	height: 53px;
	padding: 10px 16px;
	font-size: 22px;
	line-height: 1.318;
	border-radius: 6px;
}

select.input-group-hg > .form-control,
select.input-group-hg > .input-group-addon,
select.input-group-hg > .input-group-btn > .btn {
	height: 53px;
	line-height: 53px;
}

textarea.input-group-hg > .form-control,
textarea.input-group-hg > .input-group-addon,
textarea.input-group-hg > .input-group-btn > .btn,
select[multiple].input-group-hg > .form-control,
select[multiple].input-group-hg > .input-group-addon,
select[multiple].input-group-hg > .input-group-btn > .btn {
	height: auto;
}

.input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
	height: 45px;
	padding: 10px 15px;
	font-size: 17px;
	line-height: 1.235;
	border-radius: 6px;
}

select.input-group-lg > .form-control,
select.input-group-lg > .input-group-addon,
select.input-group-lg > .input-group-btn > .btn {
	height: 45px;
	line-height: 45px;
}

textarea.input-group-lg > .form-control,
textarea.input-group-lg > .input-group-addon,
textarea.input-group-lg > .input-group-btn > .btn,
select[multiple].input-group-lg > .form-control,
select[multiple].input-group-lg > .input-group-addon,
select[multiple].input-group-lg > .input-group-btn > .btn {
	height: auto;
}

.input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn {
	height: 35px;
	padding: 6px 10px;
	font-size: 13px;
	line-height: 1.462;
	border-radius: 6px;
}

select.input-group-sm > .form-control,
select.input-group-sm > .input-group-addon,
select.input-group-sm > .input-group-btn > .btn {
	height: 35px;
	line-height: 35px;
}

textarea.input-group-sm > .form-control,
textarea.input-group-sm > .input-group-addon,
textarea.input-group-sm > .input-group-btn > .btn,
select[multiple].input-group-sm > .form-control,
select[multiple].input-group-sm > .input-group-addon,
select[multiple].input-group-sm > .input-group-btn > .btn {
	height: auto;
}

.input-group-addon {
	padding: 10px 12px;
	font-size: 15px;
	color: #ffffff;
	text-align: center;
	background-color: #bdc3c7;
	border: 1px solid #bdc3c7;
	border-radius: 6px;
	-webkit-transition: border .25s linear, color .25s linear, background-color .25s linear;
	transition: border .25s linear, color .25s linear, background-color .25s linear;
}

.input-group-hg .input-group-addon,
.input-group-lg .input-group-addon,
.input-group-sm .input-group-addon {
	line-height: 1;
}

.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
}

.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child) {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
}

.form-group.focus .input-group-addon,
.input-group.focus .input-group-addon {
	background-color: #1abc9c;
	border-color: #1abc9c;
}

.form-group.focus .input-group-btn > .btn-default + .btn-default,
.input-group.focus .input-group-btn > .btn-default + .btn-default {
	border-left-color: #16a085;
}

.form-group.focus .input-group-btn .btn,
.input-group.focus .input-group-btn .btn {
	border-color: #1abc9c;
	background-color: #ffffff;
	color: #1abc9c;
}

.form-group.focus .input-group-btn .btn-default,
.input-group.focus .input-group-btn .btn-default {
	color: #ffffff;
	background-color: #1abc9c;
}

.form-group.focus .input-group-btn .btn-default:hover,
.input-group.focus .input-group-btn .btn-default:hover,
.form-group.focus .input-group-btn .btn-default:focus,
.input-group.focus .input-group-btn .btn-default:focus,
.form-group.focus .input-group-btn .btn-default:active,
.input-group.focus .input-group-btn .btn-default:active,
.form-group.focus .input-group-btn .btn-default.active,
.input-group.focus .input-group-btn .btn-default.active,
.open .dropdown-toggle.form-group.focus .input-group-btn .btn-default,
.open .dropdown-toggle.input-group.focus .input-group-btn .btn-default {
	color: #ffffff;
	background-color: #48c9b0;
	border-color: #48c9b0;
}

.form-group.focus .input-group-btn .btn-default:active,
.input-group.focus .input-group-btn .btn-default:active,
.form-group.focus .input-group-btn .btn-default.active,
.input-group.focus .input-group-btn .btn-default.active,
.open .dropdown-toggle.form-group.focus .input-group-btn .btn-default,
.open .dropdown-toggle.input-group.focus .input-group-btn .btn-default {
	background: #16a085;
	border-color: #16a085;
}

.form-group.focus .input-group-btn .btn-default.disabled,
.input-group.focus .input-group-btn .btn-default.disabled,
.form-group.focus .input-group-btn .btn-default[disabled],
.input-group.focus .input-group-btn .btn-default[disabled],
fieldset[disabled] .form-group.focus .input-group-btn .btn-default,
fieldset[disabled] .input-group.focus .input-group-btn .btn-default,
.form-group.focus .input-group-btn .btn-default.disabled:hover,
.input-group.focus .input-group-btn .btn-default.disabled:hover,
.form-group.focus .input-group-btn .btn-default[disabled]:hover,
.input-group.focus .input-group-btn .btn-default[disabled]:hover,
fieldset[disabled] .form-group.focus .input-group-btn .btn-default:hover,
fieldset[disabled] .input-group.focus .input-group-btn .btn-default:hover,
.form-group.focus .input-group-btn .btn-default.disabled:focus,
.input-group.focus .input-group-btn .btn-default.disabled:focus,
.form-group.focus .input-group-btn .btn-default[disabled]:focus,
.input-group.focus .input-group-btn .btn-default[disabled]:focus,
fieldset[disabled] .form-group.focus .input-group-btn .btn-default:focus,
fieldset[disabled] .input-group.focus .input-group-btn .btn-default:focus,
.form-group.focus .input-group-btn .btn-default.disabled:active,
.input-group.focus .input-group-btn .btn-default.disabled:active,
.form-group.focus .input-group-btn .btn-default[disabled]:active,
.input-group.focus .input-group-btn .btn-default[disabled]:active,
fieldset[disabled] .form-group.focus .input-group-btn .btn-default:active,
fieldset[disabled] .input-group.focus .input-group-btn .btn-default:active,
.form-group.focus .input-group-btn .btn-default.disabled.active,
.input-group.focus .input-group-btn .btn-default.disabled.active,
.form-group.focus .input-group-btn .btn-default[disabled].active,
.input-group.focus .input-group-btn .btn-default[disabled].active,
fieldset[disabled] .form-group.focus .input-group-btn .btn-default.active,
fieldset[disabled] .input-group.focus .input-group-btn .btn-default.active {
	background-color: #1abc9c;
	border-color: #1abc9c;
}

.input-group-btn .btn {
	background-color: #ffffff;
	border: 2px solid #bdc3c7;
	color: #bdc3c7;
	line-height: 18px;
}

.input-group-btn .btn-default {
	color: #ffffff;
	background-color: #bdc3c7;
}

.input-group-btn .btn-default:hover,
.input-group-btn .btn-default:focus,
.input-group-btn .btn-default:active,
.input-group-btn .btn-default.active,
.open .dropdown-toggle.input-group-btn .btn-default {
	color: #ffffff;
	background-color: #cacfd2;
	border-color: #cacfd2;
}

.input-group-btn .btn-default:active,
.input-group-btn .btn-default.active,
.open .dropdown-toggle.input-group-btn .btn-default {
	background: #a1a6a9;
	border-color: #a1a6a9;
}

.input-group-btn .btn-default.disabled,
.input-group-btn .btn-default[disabled],
fieldset[disabled] .input-group-btn .btn-default,
.input-group-btn .btn-default.disabled:hover,
.input-group-btn .btn-default[disabled]:hover,
fieldset[disabled] .input-group-btn .btn-default:hover,
.input-group-btn .btn-default.disabled:focus,
.input-group-btn .btn-default[disabled]:focus,
fieldset[disabled] .input-group-btn .btn-default:focus,
.input-group-btn .btn-default.disabled:active,
.input-group-btn .btn-default[disabled]:active,
fieldset[disabled] .input-group-btn .btn-default:active,
.input-group-btn .btn-default.disabled.active,
.input-group-btn .btn-default[disabled].active,
fieldset[disabled] .input-group-btn .btn-default.active {
	background-color: #bdc3c7;
	border-color: #bdc3c7;
}

.input-group-hg .input-group-btn .btn {
	line-height: 31px;
}

.input-group-lg .input-group-btn .btn {
	line-height: 21px;
}

.input-group-sm .input-group-btn .btn {
	line-height: 19px;
}

.input-group-btn:first-child > .btn {
	border-right-width: 0;
	margin-right: -2px;
}

.input-group-btn:last-child > .btn {
	border-left-width: 0;
	margin-left: -2px;
}

.input-group-btn > .btn-default + .btn-default {
	border-left: 2px solid #bdc3c7;
}

.input-group-btn > .btn:first-child + .btn .caret {
	margin-left: 0;
}

.input-group-rounded .input-group-btn + .form-control,
.input-group-rounded .input-group-btn:last-child .btn {
	border-bottom-right-radius: 20px;
	border-top-right-radius: 20px;
}

.input-group-hg.input-group-rounded .input-group-btn + .form-control,
.input-group-hg.input-group-rounded .input-group-btn:last-child .btn {
	border-bottom-right-radius: 27px;
	border-top-right-radius: 27px;
}

.input-group-lg.input-group-rounded .input-group-btn + .form-control,
.input-group-lg.input-group-rounded .input-group-btn:last-child .btn {
	border-bottom-right-radius: 25px;
	border-top-right-radius: 25px;
}

.input-group-rounded .form-control:first-child,
.input-group-rounded .input-group-btn:first-child .btn {
	border-bottom-left-radius: 20px;
	border-top-left-radius: 20px;
}

.input-group-hg.input-group-rounded .form-control:first-child,
.input-group-hg.input-group-rounded .input-group-btn:first-child .btn {
	border-bottom-left-radius: 27px;
	border-top-left-radius: 27px;
}

.input-group-lg.input-group-rounded .form-control:first-child,
.input-group-lg.input-group-rounded .input-group-btn:first-child .btn {
	border-bottom-left-radius: 25px;
	border-top-left-radius: 25px;
}

.input-group-rounded .input-group-btn + .form-control {
	padding-left: 0;
}

.checkbox,
.radio {
	margin-bottom: 12px;
	padding-left: 32px;
	position: relative;
	-webkit-transition: color 0.25s linear;
	transition: color 0.25s linear;
	font-size: 14px;
	line-height: 1.5;
}

.checkbox input,
.radio input {
	outline: none !important;
	display: none;
}

.checkbox .icons,
.radio .icons {
	color: #bdc3c7;
	display: block;
	height: 20px;
	left: 0;
	position: absolute;
	top: 0;
	width: 20px;
	text-align: center;
	line-height: 21px;
	font-size: 20px;
	cursor: pointer;
	-webkit-transition: color 0.25s linear;
	transition: color 0.25s linear;
}

.checkbox .icons .first-icon,
.radio .icons .first-icon,
.checkbox .icons .second-icon,
.radio .icons .second-icon {
	display: inline-table;
	position: absolute;
	left: 0;
	top: 0;
	background-color: transparent;
	margin: 0;
	opacity: 1;
	filter: alpha(opacity=100);
}

.checkbox .icons .second-icon,
.radio .icons .second-icon {
	opacity: 0;
	filter: alpha(opacity=0);
}

.checkbox:hover,
.radio:hover {
	-webkit-transition: color 0.25s linear;
	transition: color 0.25s linear;
}

.checkbox:hover .first-icon,
.radio:hover .first-icon {
	opacity: 0;
	filter: alpha(opacity=0);
}

.checkbox:hover .second-icon,
.radio:hover .second-icon {
	opacity: 1;
	filter: alpha(opacity=100);
}

.checkbox.checked,
.radio.checked {
	color: #1abc9c;
}

.checkbox.checked .first-icon,
.radio.checked .first-icon {
	opacity: 0;
	filter: alpha(opacity=0);
}

.checkbox.checked .second-icon,
.radio.checked .second-icon {
	opacity: 1;
	filter: alpha(opacity=100);
	color: #1abc9c;
	-webkit-transition: color 0.25s linear;
	transition: color 0.25s linear;
}

.checkbox.disabled,
.radio.disabled {
	cursor: default;
	color: #e6e8ea;
}

.checkbox.disabled .icons,
.radio.disabled .icons {
	color: #e6e8ea;
}

.checkbox.disabled .first-icon,
.radio.disabled .first-icon {
	opacity: 1;
	filter: alpha(opacity=100);
}

.checkbox.disabled .second-icon,
.radio.disabled .second-icon {
	opacity: 0;
	filter: alpha(opacity=0);
}

.checkbox.disabled.checked .icons,
.radio.disabled.checked .icons {
	color: #e6e8ea;
}

.checkbox.disabled.checked .first-icon,
.radio.disabled.checked .first-icon {
	opacity: 0;
	filter: alpha(opacity=0);
}

.checkbox.disabled.checked .second-icon,
.radio.disabled.checked .second-icon {
	opacity: 1;
	filter: alpha(opacity=100);
	color: #e6e8ea;
}

.checkbox.primary .icons,
.radio.primary .icons {
	color: #34495e;
}

.checkbox.primary.checked,
.radio.primary.checked {
	color: #1abc9c;
}

.checkbox.primary.checked .icons,
.radio.primary.checked .icons {
	color: #1abc9c;
}

.checkbox.primary.disabled,
.radio.primary.disabled {
	cursor: default;
	color: #bdc3c7;
}

.checkbox.primary.disabled .icons,
.radio.primary.disabled .icons {
	color: #bdc3c7;
}

.checkbox.primary.disabled.checked .icons,
.radio.primary.disabled.checked .icons {
	color: #bdc3c7;
}

.radio + .radio,
.checkbox + .checkbox {
	margin-top: 10px;
}

.navbar {
	font-size: 16px;
	min-height: 53px;
	border: none;
	border-radius: 6px;
}

@media (min-width: 768px) {
	.navbar-header {
		float: left;
	}
}

.navbar-collapse {
	box-shadow: none;
	padding-right: 21px;
	padding-left: 21px;
}

@media (min-width: 768px) {
	.navbar-collapse .navbar-nav.navbar-left:first-child {
		margin-left: -21px;
	}

	.navbar-collapse .navbar-nav.navbar-left:first-child > li:first-child a {
		border-bottom-left-radius: 6px;
		border-top-left-radius: 6px;
	}

	.navbar-collapse .navbar-nav.navbar-right:last-child {
		margin-right: -21px;
	}

	.navbar > .container .navbar-collapse .navbar-nav.navbar-right:last-child {
		margin-right: -36px;
	}

	.navbar-collapse .navbar-nav.navbar-right:last-child > .dropdown:last-child > a {
		border-radius: 0 6px 6px 0;
	}

	.navbar-collapse .navbar-form.navbar-right:last-child {
		margin-right: -17px;
	}

	.navbar-fixed-top .navbar-collapse .navbar-form.navbar-right:last-child,
	.navbar-fixed-bottom .navbar-collapse .navbar-form.navbar-right:last-child {
		margin-right: 0;
	}
}

@media (max-width: 767px) {
	.navbar-collapse .navbar-nav.navbar-right:last-child {
		margin-bottom: 3px;
	}
}

.container > .navbar-header,
.container-fluid > .navbar-header,
.container > .navbar-collapse,
.container-fluid > .navbar-collapse {
	margin-right: -21px;
	margin-left: -21px;
}

@media (min-width: 768px) {
	.container > .navbar-header,
	.container-fluid > .navbar-header,
	.container > .navbar-collapse,
	.container-fluid > .navbar-collapse {
		margin-right: 0;
		margin-left: 0;
	}
}

.navbar-static-top {
	z-index: 1000;
	border-width: 0;
	border-radius: 0;
}

.navbar-fixed-top,
.navbar-fixed-bottom {
	z-index: 1030;
	border-radius: 0;
}

.navbar-fixed-top {
	border-width: 0;
}

.navbar-fixed-bottom {
	margin-bottom: 0;
	border-width: 0;
}

.navbar-brand {
	font-size: 24px;
	line-height: 1.042;
	font-weight: 700;
}

.navbar-brand > [class*="fui-"] {
	font-size: 19px;
	line-height: 1.263;
	vertical-align: top;
}

@media (min-width: 768px) {
	.navbar > .container .navbar-brand,
	.navbar > .container-fluid .navbar-brand {
		margin-left: -21px;
	}
}

@media (max-width: 420px) {
	.navbar-header .navbar-brand {
		font-size: 20px;
	}
}

@media (max-width: 365px) {
	.navbar-header .navbar-brand {
		font-size: 15px;
	}
}

.navbar-toggle {
	border: none;
	color: #34495e;
	margin: 0 0 0 21px;
	padding: 0 21px;
	height: 53px;
	line-height: 53px;
}

.navbar-toggle:before {
	color: #34495e;
	content: "\e00c";
	font-family: "Flat-UI-Icons";
	font-size: 22px;
	font-style: normal;
	font-weight: normal;
	-webkit-font-smoothing: antialiased;
	-webkit-transition: color 0.25s linear;
	transition: color 0.25s linear;
}

.navbar-toggle:hover,
.navbar-toggle:focus {
	outline: none;
}

.navbar-toggle:hover:before,
.navbar-toggle:focus:before {
	color: #18bc9c;
}

.navbar-toggle .icon-bar {
	display: none;
}

@media (min-width: 768px) {
	.navbar-toggle {
		display: none;
	}
}

.navbar-nav {
	margin: 0;
}

.navbar-nav > li > a {
	font-size: 16px;
	line-height: 23px;
	font-weight: 700;
}

.navbar-nav .dropdown-toggle .caret {
	border-top-color: #16a085;
	border-bottom-color: #16a085;
	border-width: 8px 6px 0;
	margin-left: 12px;
}

.navbar-nav > li > a:hover,
.navbar-nav > li > a:focus,
.navbar-nav .open > a:focus,
.navbar-nav .open > a:hover {
	background-color: transparent;
}

.navbar-nav > li > a:hover .caret,
.navbar-nav > li > a:focus .caret,
.navbar-nav .open > a:focus .caret,
.navbar-nav .open > a:hover .caret {
	border-top-color: #1abc9c;
	border-bottom-color: #1abc9c;
}

.navbar-nav [class^="fui-"] {
	line-height: 20px;
	position: relative;
	top: 1px;
}

.navbar-nav .visible-sm > [class^="fui-"],
.navbar-nav .visible-xs > [class^="fui-"] {
	margin-left: 12px;
}

@media (max-width: 767px) {
	.navbar-nav {
		margin: 0 -21px;
	}

	.navbar-nav .open .dropdown-menu > li > a,
	.navbar-nav .open .dropdown-menu .dropdown-header {
		padding: 7px 15px 7px 31px !important;
	}

	.navbar-nav .open .dropdown-menu > li > a {
		line-height: 23px;
	}

	.navbar-nav > li > a {
		padding-top: 7px;
		padding-bottom: 7px;
		text-align: center;
		line-height: 40px;
	}
}

.navbar-input {
	height: 35px;
	padding: 5px 10px;
	font-size: 13px;
	line-height: 1.4;
	border-radius: 6px;
}

select.navbar-input {
	height: 35px;
	line-height: 35px;
}

textarea.navbar-input,
select[multiple].navbar-input {
	height: auto;
}

.navbar-form {
	-webkit-box-shadow: none;
	box-shadow: none;
	margin-top: 0;
	margin-bottom: 0;
	padding-right: 19px;
	padding-left: 19px;
	padding-top: 9px;
	padding-bottom: 9px;
}

@media (max-width: 767px) {
	.navbar-form .form-group {
		margin-bottom: 5px;
	}
}

@media (max-width: 767px) {
	.navbar-form {
		margin: 3px -21px;
		width: auto;
	}
}

@media (min-width: 768px) {
	.navbar-nav + .navbar-form.navbar-left,
	.navbar-form.navbar-right:last-child {
		width: 260px;
	}
}

.navbar-form .form-control,
.navbar-form .input-group-addon,
.navbar-form .btn {
	height: 35px;
	padding: 5px 10px;
	font-size: 13px;
	line-height: 1.4;
	border-radius: 6px;
}

select.navbar-form .form-control,
select.navbar-form .input-group-addon,
select.navbar-form .btn {
	height: 35px;
	line-height: 35px;
}

textarea.navbar-form .form-control,
textarea.navbar-form .input-group-addon,
textarea.navbar-form .btn,
select[multiple].navbar-form .form-control,
select[multiple].navbar-form .input-group-addon,
select[multiple].navbar-form .btn {
	height: auto;
}

.navbar-form .input-group .form-control:first-child,
.navbar-form .input-group-addon:first-child,
.navbar-form .input-group-btn:first-child > .btn,
.navbar-form .input-group-btn:first-child > .dropdown-toggle,
.navbar-form .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
}

.navbar-form .input-group .form-control:last-child,
.navbar-form .input-group-addon:last-child,
.navbar-form .input-group-btn:last-child > .btn,
.navbar-form .input-group-btn:last-child > .dropdown-toggle,
.navbar-form .input-group-btn:first-child > .btn:not(:first-child) {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
}

.navbar-form .form-control {
	font-size: 15px;
	border-radius: 5px;
	display: table-cell;
}

.navbar-form .form-group + .btn {
	font-size: 15px;
	border-radius: 5px;
	margin-left: 4px;
}

@media (max-width: 767px) {
	.navbar-form .form-group {
		margin-bottom: 0;
	}

	.navbar-form .form-group + .btn {
		margin-top: 9px;
		margin-left: 0;
	}
}

.navbar-nav > li > .dropdown-menu {
	min-width: 100%;
	border-radius: 4px;
}

@media (max-width: 767px) {
	.navbar-nav > li > .dropdown-menu {
		-webkit-transition: all 0s;
		transition: all 0s;
		display: none;
	}
}

@media (max-width: 767px) {
	.navbar-nav > li.open > .dropdown-menu {
		margin-top: 0 !important;
		display: block;
	}
}

.navbar-fixed-bottom .navbar-nav > li > .dropdown-menu {
	border-bottom-right-radius: 4px;
	border-bottom-left-radius: 4px;
}

.navbar-nav > .open > .dropdown-toggle,
.navbar-nav > .open > .dropdown-toggle:focus,
.navbar-nav > .open > .dropdown-toggle:hover {
	background-color: transparent;
}

.navbar-text {
	font-size: 16px;
	line-height: 1.438;
	color: #34495e;
	margin-top: 0;
	margin-bottom: 0;
	padding-top: 15px;
	padding-bottom: 15px;
}

@media (min-width: 768px) {
	.navbar-text {
		margin-left: 21px;
		margin-right: 21px;
	}

	.navbar-text.navbar-right:last-child {
		margin-right: 0;
	}
}

.navbar-btn {
	margin-top: 6px;
	margin-bottom: 6px;
}

.navbar-btn.btn-sm {
	margin-top: 9px;
	margin-bottom: 8px;
}

.navbar-btn.btn-xs {
	margin-top: 14px;
	margin-bottom: 14px;
}

.navbar-unread,
.navbar-new {
	font-family: "Lato", Helvetica, Arial, sans-serif;
	background-color: #1abc9c;
	border-radius: 50%;
	color: #ffffff;
	font-size: 0;
	font-weight: 700;
	height: 6px;
	line-height: 1;
	position: absolute;
	right: 12px;
	text-align: center;
	top: 35%;
	width: 6px;
	z-index: 10;
}

@media (max-width: 768px) {
	.navbar-unread,
	.navbar-new {
		position: static;
		float: right;
		margin: 0 0 0 10px;
	}
}

.active .navbar-unread,
.active .navbar-new {
	background-color: #ffffff;
	display: none;
}

.navbar-new {
	background-color: #e74c3c;
	font-size: 12px;
	height: 18px;
	line-height: 17px;
	margin: -6px -10px;
	min-width: 18px;
	padding: 0 1px;
	width: auto;
	-webkit-font-smoothing: subpixel-antialiased;
}

.navbar-default {
	background-color: #ecf0f1;
}

.navbar-default .navbar-brand {
	color: #34495e;
}

.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus {
	color: #1abc9c;
	background-color: transparent;
}

.navbar-default .navbar-toggle:before {
	color: #34495e;
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
	background-color: transparent;
}

.navbar-default .navbar-toggle:hover:before,
.navbar-default .navbar-toggle:focus:before {
	color: #1abc9c;
}

.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
	border-color: #e5e9ea;
	border-width: 2px;
}

.navbar-default .navbar-nav > li > a {
	color: #34495e;
}

.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
	color: #1abc9c;
	background-color: transparent;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
	color: #1abc9c;
	background-color: transparent;
}

.navbar-default .navbar-nav > .disabled > a,
.navbar-default .navbar-nav > .disabled > a:hover,
.navbar-default .navbar-nav > .disabled > a:focus {
	color: #cccccc;
	background-color: transparent;
}

.navbar-default .navbar-nav > .dropdown > a .caret {
	border-top-color: #34495e;
	border-bottom-color: #34495e;
}

.navbar-default .navbar-nav > .active > a .caret {
	border-top-color: #1abc9c;
	border-bottom-color: #1abc9c;
}

.navbar-default .navbar-nav > .dropdown > a:hover .caret,
.navbar-default .navbar-nav > .dropdown > a:focus .caret {
	border-top-color: #1abc9c;
	border-bottom-color: #1abc9c;
}

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
	background-color: transparent;
	color: #1abc9c;
}

.navbar-default .navbar-nav > .open > a .caret,
.navbar-default .navbar-nav > .open > a:hover .caret,
.navbar-default .navbar-nav > .open > a:focus .caret {
	border-top-color: #1abc9c;
	border-bottom-color: #1abc9c;
}

@media (max-width: 767px) {
	.navbar-default .navbar-nav .open .dropdown-menu > li > a {
		color: #34495e;
	}

	.navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
	.navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
		color: #1abc9c;
		background-color: transparent;
	}

	.navbar-default .navbar-nav .open .dropdown-menu > .active > a,
	.navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover,
	.navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
		color: #1abc9c;
		background-color: transparent;
	}

	.navbar-default .navbar-nav .open .dropdown-menu > .disabled > a,
	.navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:hover,
	.navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:focus {
		color: #cccccc;
		background-color: transparent;
	}
}

.navbar-default .navbar-form .form-control {
	border-color: transparent;
}

.navbar-default .navbar-form .form-control:-moz-placeholder {
	color: #aeb6bf;
}

.navbar-default .navbar-form .form-control::-moz-placeholder {
	color: #aeb6bf;
	opacity: 1;
}

.navbar-default .navbar-form .form-control:-ms-input-placeholder {
	color: #aeb6bf;
}

.navbar-default .navbar-form .form-control::-webkit-input-placeholder {
	color: #aeb6bf;
}

.navbar-default .navbar-form .form-control.placeholder {
	color: #aeb6bf;
}

.navbar-default .navbar-form .form-control:focus {
	border-color: #1abc9c;
	color: #1abc9c;
}

.navbar-default .navbar-form .input-group-btn .btn {
	border-color: transparent;
	color: #919ba4;
}

.navbar-default .navbar-form .input-group.focus .form-control,
.navbar-default .navbar-form .input-group.focus .input-group-btn .btn {
	border-color: #1abc9c;
	color: #1abc9c;
}

.navbar-default .navbar-text {
	color: #34495e;
}

.navbar-default .navbar-text a {
	color: #34495e;
}

.navbar-default .navbar-text a:hover,
.navbar-default .navbar-text a:focus {
	color: #1abc9c;
}

.navbar-inverse {
	background-color: #34495e;
}

.navbar-inverse .navbar-brand {
	color: #ffffff;
}

.navbar-inverse .navbar-brand:hover,
.navbar-inverse .navbar-brand:focus {
	color: #1abc9c;
	background-color: transparent;
}

.navbar-inverse .navbar-toggle:before {
	color: #ffffff;
}

.navbar-inverse .navbar-toggle:hover,
.navbar-inverse .navbar-toggle:focus {
	background-color: transparent;
}

.navbar-inverse .navbar-toggle:hover:before,
.navbar-inverse .navbar-toggle:focus:before {
	color: #1abc9c;
}

.navbar-inverse .navbar-collapse {
	border-color: #2f4154;
	border-width: 2px;
}

.navbar-inverse .navbar-nav > li > a {
	color: #ffffff;
}

.navbar-inverse .navbar-nav > li > a:hover,
.navbar-inverse .navbar-nav > li > a:focus {
	color: #1abc9c;
	background-color: transparent;
}

.navbar-inverse .navbar-nav > .active > a,
.navbar-inverse .navbar-nav > .active > a:hover,
.navbar-inverse .navbar-nav > .active > a:focus {
	color: #ffffff;
	background-color: #1abc9c;
}

.navbar-inverse .navbar-nav > .disabled > a,
.navbar-inverse .navbar-nav > .disabled > a:hover,
.navbar-inverse .navbar-nav > .disabled > a:focus {
	color: #444444;
	background-color: transparent;
}

.navbar-inverse .navbar-nav > .dropdown > a:hover .caret,
.navbar-inverse .navbar-nav > .dropdown > a:focus .caret {
	border-top-color: #1abc9c;
	border-bottom-color: #1abc9c;
}

.navbar-inverse .navbar-nav > .open > a,
.navbar-inverse .navbar-nav > .open > a:hover,
.navbar-inverse .navbar-nav > .open > a:focus {
	background-color: #1abc9c;
	color: #ffffff;
	border-left-color: transparent;
}

.navbar-inverse .navbar-nav > .open > a .caret,
.navbar-inverse .navbar-nav > .open > a:hover .caret,
.navbar-inverse .navbar-nav > .open > a:focus .caret {
	border-top-color: #ffffff;
	border-bottom-color: #ffffff;
}

.navbar-inverse .navbar-nav > .dropdown > a .caret {
	border-top-color: #4b6075;
	border-bottom-color: #4b6075;
}

.navbar-inverse .navbar-nav > .open > .dropdown-arrow {
	border-top-color: #34495e;
	border-bottom-color: #34495e;
}

.navbar-inverse .navbar-nav > .open > .dropdown-menu {
	background-color: #34495e;
	padding: 3px 4px;
}

.navbar-inverse .navbar-nav > .open > .dropdown-menu > li > a {
	color: #e1e4e7;
	border-radius: 4px;
	padding: 6px 9px;
}

.navbar-inverse .navbar-nav > .open > .dropdown-menu > li > a:hover,
.navbar-inverse .navbar-nav > .open > .dropdown-menu > li > a:focus {
	color: #ffffff;
	background-color: #1abc9c;
}

.navbar-inverse .navbar-nav > .open > .dropdown-menu > .divider {
	background-color: #2f4154;
	height: 2px;
	margin-left: -4px;
	margin-right: -4px;
}

@media (max-width: 767px) {
	.navbar-inverse .navbar-nav > li > a {
		border-left-width: 0;
	}

	.navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
		color: #ffffff;
	}

	.navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover,
	.navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus {
		color: #1abc9c;
		background-color: transparent;
	}

	.navbar-inverse .navbar-nav .open .dropdown-menu > .active > a,
	.navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:hover,
	.navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:focus {
		color: #ffffff;
		background-color: #1abc9c;
	}

	.navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a,
	.navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:hover,
	.navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:focus {
		color: #444444;
		background-color: transparent;
	}

	.navbar-inverse .navbar-nav .dropdown-menu .divider {
		background-color: #2f4154;
	}
}

.navbar-inverse .navbar-form .form-control {
	color: #536a81;
	border-color: transparent;
	background-color: #293a4a;
}

.navbar-inverse .navbar-form .form-control:-moz-placeholder {
	color: #536a81;
}

.navbar-inverse .navbar-form .form-control::-moz-placeholder {
	color: #536a81;
	opacity: 1;
}

.navbar-inverse .navbar-form .form-control:-ms-input-placeholder {
	color: #536a81;
}

.navbar-inverse .navbar-form .form-control::-webkit-input-placeholder {
	color: #536a81;
}

.navbar-inverse .navbar-form .form-control.placeholder {
	color: #536a81;
}

.navbar-inverse .navbar-form .form-control:focus {
	border-color: #1abc9c;
	color: #1abc9c;
}

.navbar-inverse .navbar-form .btn {
	color: #ffffff;
	background-color: #1abc9c;
}

.navbar-inverse .navbar-form .btn:hover,
.navbar-inverse .navbar-form .btn:focus,
.navbar-inverse .navbar-form .btn:active,
.navbar-inverse .navbar-form .btn.active,
.open .dropdown-toggle.navbar-inverse .navbar-form .btn {
	color: #ffffff;
	background-color: #48c9b0;
	border-color: #48c9b0;
}

.navbar-inverse .navbar-form .btn:active,
.navbar-inverse .navbar-form .btn.active,
.open .dropdown-toggle.navbar-inverse .navbar-form .btn {
	background: #16a085;
	border-color: #16a085;
}

.navbar-inverse .navbar-form .btn.disabled,
.navbar-inverse .navbar-form .btn[disabled],
fieldset[disabled] .navbar-inverse .navbar-form .btn,
.navbar-inverse .navbar-form .btn.disabled:hover,
.navbar-inverse .navbar-form .btn[disabled]:hover,
fieldset[disabled] .navbar-inverse .navbar-form .btn:hover,
.navbar-inverse .navbar-form .btn.disabled:focus,
.navbar-inverse .navbar-form .btn[disabled]:focus,
fieldset[disabled] .navbar-inverse .navbar-form .btn:focus,
.navbar-inverse .navbar-form .btn.disabled:active,
.navbar-inverse .navbar-form .btn[disabled]:active,
fieldset[disabled] .navbar-inverse .navbar-form .btn:active,
.navbar-inverse .navbar-form .btn.disabled.active,
.navbar-inverse .navbar-form .btn[disabled].active,
fieldset[disabled] .navbar-inverse .navbar-form .btn.active {
	background-color: #1abc9c;
	border-color: #1abc9c;
}

.navbar-inverse .navbar-form .input-group-btn .btn {
	border-color: transparent;
	background-color: #293a4a;
	color: #526a82;
}

.navbar-inverse .navbar-form .input-group.focus .form-control,
.navbar-inverse .navbar-form .input-group.focus .input-group-btn .btn {
	border-color: #1abc9c;
	color: #1abc9c;
}

@media (max-width: 767px) {
	.navbar-inverse .navbar-form {
		border-color: #2f4154;
		border-width: 2px 0;
	}
}

.navbar-inverse .navbar-text {
	color: #ffffff;
}

.navbar-inverse .navbar-text a {
	color: #ffffff;
}

.navbar-inverse .navbar-text a:hover,
.navbar-inverse .navbar-text a:focus {
	color: #1abc9c;
}

.navbar-inverse .navbar-btn {
	color: #ffffff;
	background-color: #1abc9c;
}

.navbar-inverse .navbar-btn:hover,
.navbar-inverse .navbar-btn:focus,
.navbar-inverse .navbar-btn:active,
.navbar-inverse .navbar-btn.active,
.open .dropdown-toggle.navbar-inverse .navbar-btn {
	color: #ffffff;
	background-color: #48c9b0;
	border-color: #48c9b0;
}

.navbar-inverse .navbar-btn:active,
.navbar-inverse .navbar-btn.active,
.open .dropdown-toggle.navbar-inverse .navbar-btn {
	background: #16a085;
	border-color: #16a085;
}

.navbar-inverse .navbar-btn.disabled,
.navbar-inverse .navbar-btn[disabled],
fieldset[disabled] .navbar-inverse .navbar-btn,
.navbar-inverse .navbar-btn.disabled:hover,
.navbar-inverse .navbar-btn[disabled]:hover,
fieldset[disabled] .navbar-inverse .navbar-btn:hover,
.navbar-inverse .navbar-btn.disabled:focus,
.navbar-inverse .navbar-btn[disabled]:focus,
fieldset[disabled] .navbar-inverse .navbar-btn:focus,
.navbar-inverse .navbar-btn.disabled:active,
.navbar-inverse .navbar-btn[disabled]:active,
fieldset[disabled] .navbar-inverse .navbar-btn:active,
.navbar-inverse .navbar-btn.disabled.active,
.navbar-inverse .navbar-btn[disabled].active,
fieldset[disabled] .navbar-inverse .navbar-btn.active {
	background-color: #1abc9c;
	border-color: #1abc9c;
}

@media (min-width: 768px) {
	.navbar-embossed > .navbar-collapse {
		border-radius: 6px;
		-webkit-box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.15);
		box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.15);
	}

	.navbar-embossed.navbar-inverse .navbar-nav .active > a,
	.navbar-embossed.navbar-inverse .navbar-nav .open > a {
		-webkit-box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.15);
		box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.15);
	}
}

.navbar-lg {
	min-height: 76px;
}

.navbar-lg .navbar-brand {
	line-height: 1;
	padding-top: 26px;
	padding-bottom: 26px;
}

.navbar-lg .navbar-brand > [class*="fui-"] {
	font-size: 24px;
	line-height: 1;
}

.navbar-lg .navbar-nav > li > a {
	font-size: 15px;
	line-height: 1.6;
}

@media (min-width: 768px) {
	.navbar-lg .navbar-nav > li > a {
		padding-top: 26px;
		padding-bottom: 26px;
	}
}

.navbar-lg .navbar-toggle {
	height: 76px;
	line-height: 76px;
}

.navbar-lg .navbar-form {
	padding-top: 20.5px;
	padding-bottom: 20.5px;
}

.navbar-lg .navbar-text {
	padding-top: 26.5px;
	padding-bottom: 26.5px;
}

.navbar-lg .navbar-btn {
	margin-top: 17.5px;
	margin-bottom: 17.5px;
}

.navbar-lg .navbar-btn.btn-sm {
	margin-top: 20.5px;
	margin-bottom: 20.5px;
}

.navbar-lg .navbar-btn.btn-xs {
	margin-top: 25.5px;
	margin-bottom: 25.5px;
}

.tagsinput {
	background: white;
	border: 2px solid #1abc9c;
	border-radius: 6px;
	height: 100px;
	margin-bottom: 18px;
	padding: 6px 1px 1px 6px;
	overflow-y: auto;
	text-align: left;
}

.tagsinput .tag {
	border-radius: 4px;
	background-color: #1abc9c;
	color: #ffffff;
	font-size: 14px;
	cursor: pointer;
	display: inline-block;
	margin-right: 5px;
	margin-bottom: 5px;
	overflow: hidden;
	line-height: 15px;
	padding: 6px 13px 8px 19px;
	position: relative;
	vertical-align: middle;
	-webkit-transition: 0.25s linear;
	transition: 0.25s linear;
}

.tagsinput .tag:hover {
	background-color: #16a085;
	color: #ffffff;
	padding-left: 12px;
	padding-right: 20px;
}

.tagsinput .tag:hover .tagsinput-remove-link {
	color: #ffffff;
	opacity: 1;
	display: block \9;
}

.tagsinput input {
	background: transparent;
	border: none;
	color: #34495e;
	font-family: "Lato", Helvetica, Arial, sans-serif;
	font-size: 14px;
	margin: 0px;
	padding: 0 0 0 5px;
	outline: none !important;
	margin: 6px 5px 0 0;
	vertical-align: top;
	width: 12px;
}

.tagsinput-remove-link {
	bottom: 0;
	color: #ffffff;
	cursor: pointer;
	font-size: 12px;
	opacity: 0;
	padding: 7px 7px 5px 0;
	position: absolute;
	right: 0;
	text-align: right;
	text-decoration: none;
	top: 0;
	width: 100%;
	z-index: 2;
	display: none \9;
}

.tagsinput-remove-link:before {
	color: #ffffff;
	content: "\e00b";
	font-family: "Flat-UI-Icons";
}

.tagsinput-add-container {
	vertical-align: middle;
	display: inline-block;
}

.tagsinput-add {
	background-color: #d6dbdf;
	border-radius: 3px;
	color: #ffffff;
	cursor: pointer;
	display: inline-block;
	font-size: 14px;
	line-height: 1;
	margin-bottom: 5px;
	padding: 7px 9px;
	vertical-align: top;
	-webkit-transition: 0.25s linear;
	transition: 0.25s linear;
}

.tagsinput-add:hover {
	background-color: #1abc9c;
}

.tagsinput-add:before {
	content: "\e009";
	font-family: "Flat-UI-Icons";
}

.tags_clear {
	clear: both;
	width: 100%;
	height: 0px;
}

.not_valid {
	background: #fbd8db !important;
	color: #90111a !important;
	margin-left: 5px !important;
}

.twitter-typeahead {
	width: 100%;
}

.twitter-typeahead .tt-query,
.twitter-typeahead .tt-hint {
	border: 2px solid #bdc3c7;
	color: #34495e;
	font-family: "Lato", Helvetica, Arial, sans-serif;
	font-size: 15px;
	line-height: 1.467;
	padding: 8px 12px;
	height: 42px;
	-webkit-appearance: none;
	border-radius: 6px;
	-webkit-box-shadow: none;
	box-shadow: none;
	-webkit-transition: border .25s linear, color .25s linear, background-color .25s linear;
	transition: border .25s linear, color .25s linear, background-color .25s linear;
}

.twitter-typeahead .tt-query:-moz-placeholder,
.twitter-typeahead .tt-hint:-moz-placeholder {
	color: #b2bcc5;
}

.twitter-typeahead .tt-query::-moz-placeholder,
.twitter-typeahead .tt-hint::-moz-placeholder {
	color: #b2bcc5;
	opacity: 1;
}

.twitter-typeahead .tt-query:-ms-input-placeholder,
.twitter-typeahead .tt-hint:-ms-input-placeholder {
	color: #b2bcc5;
}

.twitter-typeahead .tt-query::-webkit-input-placeholder,
.twitter-typeahead .tt-hint::-webkit-input-placeholder {
	color: #b2bcc5;
}

.twitter-typeahead .tt-query.placeholder,
.twitter-typeahead .tt-hint.placeholder {
	color: #b2bcc5;
}

.form-group.focus .twitter-typeahead .tt-query,
.form-group.focus .twitter-typeahead .tt-hint,
.twitter-typeahead .tt-query:focus,
.twitter-typeahead .tt-hint:focus {
	border-color: #1abc9c;
	outline: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.twitter-typeahead .tt-query[disabled],
.twitter-typeahead .tt-hint[disabled],
.twitter-typeahead .tt-query[readonly],
.twitter-typeahead .tt-hint[readonly],
fieldset[disabled] .twitter-typeahead .tt-query,
fieldset[disabled] .twitter-typeahead .tt-hint {
	background-color: #f4f6f6;
	border-color: #d5dbdb;
	color: #d5dbdb;
	cursor: default;
	opacity: 0.7;
	filter: alpha(opacity=70);
}

.twitter-typeahead .tt-query.flat,
.twitter-typeahead .tt-hint.flat {
	border-color: transparent;
}

.twitter-typeahead .tt-query.flat:hover,
.twitter-typeahead .tt-hint.flat:hover {
	border-color: #bdc3c7;
}

.twitter-typeahead .tt-query.flat:focus,
.twitter-typeahead .tt-hint.flat:focus {
	border-color: #1abc9c;
}

.twitter-typeahead .tt-query + .input-icon,
.twitter-typeahead .tt-hint + .input-icon {
	position: absolute;
	top: 2px;
	right: 2px;
	line-height: 37px;
	vertical-align: middle;
	font-size: 20px;
	color: #b2bcc5;
	background-color: #ffffff;
	padding: 0 12px 0 0;
	border-radius: 6px;
}

.twitter-typeahead .tt-dropdown-menu {
	width: 100%;
	margin-top: 5px;
	border: 2px solid #1abc9c;
	padding: 5px 0;
	background-color: #ffffff;
	border-radius: 6px;
}

.twitter-typeahead .tt-suggestion p {
	padding: 6px 14px;
	font-size: 14px;
	line-height: 1.429;
	margin: 0;
}

.twitter-typeahead .tt-suggestion:first-child p,
.twitter-typeahead .tt-suggestion:last-child p {
	padding: 6px 14px;
}

.twitter-typeahead .tt-suggestion.tt-is-under-cursor {
	color: #fff;
	background-color: #16a085;
}

.progress {
	background: #ebedef;
	border-radius: 32px;
	height: 12px;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.progress-bar {
	background: #1abc9c;
	line-height: 12px;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.progress-bar-success {
	background-color: #2ecc71;
}

.progress-bar-warning {
	background-color: #f1c40f;
}

.progress-bar-danger {
	background-color: #e74c3c;
}

.progress-bar-info {
	background-color: #3498db;
}

.ui-slider {
	background: #ebedef;
	border-radius: 32px;
	height: 12px;
	-webkit-box-shadow: none;
	box-shadow: none;
	margin-bottom: 20px;
	position: relative;
	cursor: pointer;
}

.ui-slider-handle {
	background-color: #16a085;
	border-radius: 50%;
	cursor: pointer;
	height: 18px;
	position: absolute;
	width: 18px;
	z-index: 2;
	-webkit-transition: background 0.25s;
	transition: background 0.25s;
}

.ui-slider-handle:hover,
.ui-slider-handle:focus {
	background-color: #48c9b0;
	outline: none;
}

.ui-slider-handle:active {
	background-color: #16a085;
}

.ui-slider-range {
	background-color: #1abc9c;
	display: block;
	height: 100%;
	position: absolute;
	z-index: 1;
}

.ui-slider-segment {
	background-color: #d9dbdd;
	border-radius: 50%;
	height: 6px;
	width: 6px;
}

.ui-slider-value {
	float: right;
	font-size: 13px;
	margin-top: 12px;
}

.ui-slider-value.first {
	clear: left;
	float: left;
}

.ui-slider-horizontal .ui-slider-handle {
	margin-left: -9px;
	top: -3px;
}

.ui-slider-horizontal .ui-slider-handle[style*="100"] {
	margin-left: -15px;
}

.ui-slider-horizontal .ui-slider-range {
	border-radius: 30px 0 0 30px;
}

.ui-slider-horizontal .ui-slider-segment {
	float: left;
	margin: 3px -6px 0 0;
}

.ui-slider-vertical {
	width: 12px;
}

.ui-slider-vertical .ui-slider-handle {
	margin-left: -3px;
	margin-bottom: -11px;
	top: auto;
}

.ui-slider-vertical .ui-slider-range {
	width: 100%;
	bottom: 0;
	border-radius: 0 0 30px 30px;
}

.ui-slider-vertical .ui-slider-segment {
	position: absolute;
	right: 3px;
}

.pager {
	background-color: #34495e;
	border-radius: 6px;
	color: #ffffff;
	font-size: 16px;
	font-weight: 700;
	display: inline-block;
}

.pager li:first-child > a,
.pager li:first-child > span {
	border-left: none;
	border-radius: 6px 0 0 6px;
}

.pager li > a,
.pager li > span {
	background: none;
	border: none;
	border-left: 2px solid #34495e;
	color: #ffffff;
	padding: 9px 15px 10px;
	text-decoration: none;
	white-space: nowrap;
	border-radius: 0 6px 6px 0;
	line-height: 1.313;
}

.pager li > a:hover,
.pager li > span:hover,
.pager li > a:focus,
.pager li > span:focus {
	background-color: #34495e;
}

.pager li > a:active,
.pager li > span:active {
	background-color: #34495e;
}

.pager li > a [class*="fui-"] + span,
.pager li > span [class*="fui-"] + span {
	margin-left: 8px;
}

.pager li > a span + [class*="fui-"],
.pager li > span span + [class*="fui-"] {
	margin-left: 8px;
}

.pagination {
	position: relative;
}

.pagination ul {
	background: #d6dbdf;
	color: #ffffff;
	padding: 0;
	margin: 0;
	display: inline-block;
	border-radius: 6px;
}

.pagination ul li {
	display: inline-block;
	margin-right: -3px;
	vertical-align: middle;
}

.pagination ul li:first-child {
	border-radius: 6px 0 0 6px;
}

.pagination ul li:first-child.previous + li > a,
.pagination ul li:first-child.previous + li > span {
	border-left-width: 5px;
}

.pagination ul li:last-child {
	border-radius: 0 6px 6px 0;
	margin-right: 0;
}

.pagination ul li.previous > a,
.pagination ul li.next > a,
.pagination ul li.previous > span,
.pagination ul li.next > span {
	background: transparent;
	border: none;
	border-right: 2px solid #e4e7ea;
	font-size: 16px;
	margin: 0 9px 0 0;
	padding: 12px 17px;
	border-radius: 6px 0 0 6px;
}

.pagination ul li.previous > a,
.pagination ul li.next > a,
.pagination ul li.previous > span,
.pagination ul li.next > span,
.pagination ul li.previous > a:hover,
.pagination ul li.next > a:hover,
.pagination ul li.previous > span:hover,
.pagination ul li.next > span:hover,
.pagination ul li.previous > a:focus,
.pagination ul li.next > a:focus,
.pagination ul li.previous > span:focus,
.pagination ul li.next > span:focus {
	border-color: #e4e7ea !important;
}

@media (max-width: 480px) {
	.pagination ul li.previous > a,
	.pagination ul li.next > a,
	.pagination ul li.previous > span,
	.pagination ul li.next > span {
		margin-right: 0;
	}
}

.pagination ul li.next {
	margin-left: 9px;
}

.pagination ul li.next > a,
.pagination ul li.next > span {
	border-left: 2px solid #e4e7ea;
	border-right: none;
	margin: 0;
	border-radius: 0 6px 6px 0;
}

.pagination ul li.active > a,
.pagination ul li.active > span {
	background-color: #ffffff;
	border-color: #ffffff;
	border-width: 2px !important;
	color: #d6dbdf;
	margin: 10px 5px 9px;
}

.pagination ul li.active > a:hover,
.pagination ul li.active > span:hover,
.pagination ul li.active > a:focus,
.pagination ul li.active > span:focus {
	background-color: #ffffff;
	border-color: #ffffff;
	color: #d6dbdf;
}

.pagination ul li.active.previous,
.pagination ul li.active.next {
	border-color: #e4e7ea;
}

.pagination ul li.active.previous > a,
.pagination ul li.active.next > a,
.pagination ul li.active.previous > span,
.pagination ul li.active.next > span {
	margin: 0;
}

.pagination ul li.active.previous > a,
.pagination ul li.active.next > a,
.pagination ul li.active.previous > span,
.pagination ul li.active.next > span,
.pagination ul li.active.previous > a:hover,
.pagination ul li.active.next > a:hover,
.pagination ul li.active.previous > span:hover,
.pagination ul li.active.next > span:hover,
.pagination ul li.active.previous > a:focus,
.pagination ul li.active.next > a:focus,
.pagination ul li.active.previous > span:focus,
.pagination ul li.active.next > span:focus {
	background-color: #1abc9c;
	color: #ffffff;
}

.pagination ul li.active.previous {
	margin-right: 6px;
}

.pagination ul li > a,
.pagination ul li > span {
	display: inline-block;
	background: #ffffff;
	border: 5px solid #d6dbdf;
	color: #ffffff;
	font-size: 14px;
	line-height: 16px;
	margin: 7px 2px 6px;
	min-width: 0;
	min-height: 16px;
	padding: 0 4px;
	border-radius: 50px;
	-webkit-transition: background .2s ease-out, border-color 0s ease-out, color .2s ease-out;
	transition: background .2s ease-out, border-color 0s ease-out, color .2s ease-out;
}

.pagination ul li > a:hover,
.pagination ul li > span:hover,
.pagination ul li > a:focus,
.pagination ul li > span:focus {
	background-color: #1abc9c;
	border-color: #1abc9c;
	color: #ffffff;
	-webkit-transition: background .2s ease-out, border-color .2s ease-out, color .2s ease-out;
	transition: background .2s ease-out, border-color .2s ease-out, color .2s ease-out;
}

.pagination ul li > a:active,
.pagination ul li > span:active {
	background-color: #16a085;
	border-color: #16a085;
}

.pagination > .btn.previous,
.pagination > .btn.next {
	margin-right: 8px;
	font-size: 14px;
	line-height: 1.429;
	padding-left: 23px;
	padding-right: 23px;
}

.pagination > .btn.previous [class*="fui-"],
.pagination > .btn.next [class*="fui-"] {
	font-size: 16px;
	margin-left: -2px;
	margin-top: -2px;
}

.pagination > .btn.next {
	margin-left: 8px;
	margin-right: 0;
}

.pagination > .btn.next [class*="fui-"] {
	margin-right: -2px;
	margin-left: 4px;
}

.tooltip {
	font-size: 14px;
	line-height: 1.286;
}

.tooltip.in {
	opacity: 1;
}

.tooltip.top {
	padding-bottom: 9px;
}

.tooltip.top .tooltip-arrow {
	border-top-color: #34495e;
	border-width: 9px 9px 0;
	bottom: 0;
	margin-left: -9px;
}

.tooltip.right .tooltip-arrow {
	border-right-color: #34495e;
	border-width: 9px 9px 9px 0;
	margin-top: -9px;
	left: -3px;
}

.tooltip.bottom {
	padding-top: 8px;
}

.tooltip.bottom .tooltip-arrow {
	border-bottom-color: #34495e;
	border-width: 0 9px 9px;
	margin-left: -9px;
	top: -1px;
}

.tooltip.left .tooltip-arrow {
	border-left-color: #34495e;
	border-width: 9px 0 9px 9px;
	margin-top: -9px;
	right: -3px;
}

.tooltip-inner {
	background-color: #34495e;
	line-height: 1.286;
	padding: 12px 12px;
	text-align: center;
	width: 183px;
	border-radius: 6px;
}

.dropdown-menu {
	background-color: #f3f4f5;
	border: none;
	display: block;
	margin-top: 8px;
	opacity: 0;
	padding: 0;
	visibility: hidden;
	-webkit-box-shadow: none;
	box-shadow: none;
	-webkit-transition: 0.25s;
	transition: 0.25s;
}

.open > .dropdown-menu {
	margin-top: 18px !important;
	opacity: 1;
	visibility: visible;
}

.dropdown-menu li:first-child dt + a {
	border-radius: 0;
}

.dropdown-menu li:first-child > a {
	border-radius: 6px 6px 0 0;
	padding-top: 8px;
}

.dropdown-menu li:last-child > a {
	border-radius: 0 0 6px 6px;
	padding-bottom: 10px;
}

.dropdown-menu li.active > a,
.dropdown-menu li.selected > a,
.dropdown-menu li.active > a.highlighted,
.dropdown-menu li.selected > a.highlighted {
	background: #1abc9c;
	color: #ffffff;
}

.dropdown-menu li.active > a:hover,
.dropdown-menu li.selected > a:hover,
.dropdown-menu li.active > a.highlighted:hover,
.dropdown-menu li.selected > a.highlighted:hover,
.dropdown-menu li.active > a:focus,
.dropdown-menu li.selected > a:focus,
.dropdown-menu li.active > a.highlighted:focus,
.dropdown-menu li.selected > a.highlighted:focus {
	background: #16a085;
	color: #ffffff;
}

.dropdown-menu li > a {
	color: rgba(52, 73, 94, 0.75);
	padding: 6px 15px 8px;
	text-decoration: none;
	-webkit-transition: background-color 0.25s;
	transition: background-color 0.25s;
}

.dropdown-menu li > a:before,
.dropdown-menu li > a:after {
	content: " ";
	/* 1 */

	display: table;
	/* 2 */

}

.dropdown-menu li > a:after {
	clear: both;
}

.dropdown-menu li > a:hover,
.dropdown-menu li > a:active,
.dropdown-menu li > a:focus {
	background: #e1e4e7;
	color: inherit;
	outline: none;
}

.dropdown-menu li > a.highlighted {
	background: #c9cfd4;
	color: #ffffff;
}

.dropdown-menu li > a.highlighted:hover,
.dropdown-menu li > a.highlighted:focus {
	background: #bac1c8;
	color: #ffffff;
}

.dropdown-menu li > a:before {
	float: right;
	margin-top: 3px;
}

.dropdown-menu li dt {
	font-weight: 300;
	margin-bottom: 3px;
	margin-top: 12px;
	padding: 0 15px;
}

@media (max-width: 991px) {
	.dropdown-menu {
		border-radius: 0 0 6px 6px !important;
	}
}

.dropdown-menu .divider {
	margin-top: 3px;
	margin-bottom: 3px;
}

.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
	margin-bottom: 8px;
}

.dropup .dropdown-arrow,
.navbar-fixed-bottom .dropdown .dropdown-arrow {
	border-bottom: none;
	border-top: 8px outset #f3f4f5;
	bottom: 100%;
	top: auto;
}

.navbar-fixed-bottom .nav > li > ul:before {
	border-bottom: none;
	border-top: 9px outset #34495e;
	bottom: 4px;
	top: auto;
}

.open.dropup > .dropdown-menu {
	margin-bottom: 18px;
}

.open.dropup > .dropdown-arrow {
	margin-bottom: 10px;
}

.open.dropup > .dropdown-arrow.dropdown-arrow-inverse {
	border-top-color: #34495e;
}

.open > .dropdown-arrow {
	margin-top: 11px;
	opacity: 1;
}

.dropdown-arrow {
	border-style: solid;
	border-width: 0 8px 7px;
	border-color: transparent transparent #f3f4f5 transparent;
	height: 0;
	margin-top: 0;
	opacity: 0;
	position: absolute;
	right: 19px;
	top: 100%;
	width: 0;
	z-index: 10;
	-webkit-transform: rotate(360deg);
	-webkit-transition: 0.25s;
	transition: 0.25s;
}

.dropdown-inverse {
	background-color: #34495e;
	color: #cccccc;
	padding: 4px 0 6px;
}

.dropdown-inverse li {
	margin: 0 4px -2px;
}

.dropdown-inverse li:first-child > a,
.dropdown-inverse li:last-child > a {
	border-radius: 2px;
	padding-bottom: 7px;
	padding-top: 5px;
}

.dropdown-inverse li:first-child dt + a,
.dropdown-inverse li:last-child dt + a {
	border-radius: 2px;
}

.dropdown-inverse li.active > a,
.dropdown-inverse li.selected > a {
	background: #1abc9c;
	color: #ffffff;
	position: relative;
	z-index: 1;
}

.dropdown-inverse li dt {
	padding-left: 11px;
	padding-right: 11px;
}

.dropdown-inverse li .divider {
	margin-left: 11px;
	margin-right: 11px;
}

.dropdown-inverse li > a {
	border-radius: 2px;
	color: #ffffff;
	padding: 5px 11px 7px;
}

.dropdown-inverse li > a:hover,
.dropdown-inverse li > a:active,
.dropdown-inverse li > a:focus {
	background: #34495e;
}

.dropdown-inverse li > a.highlighted {
	background: #526476;
}

.dropdown-inverse li > a.highlighted:hover,
.dropdown-inverse li > a.highlighted:focus {
	background: #677786;
}

.dropdown-inverse li .divider {
	background-color: #526476;
	border-bottom-color: #526476;
}

/* ============================================================
 * bootstrapSwitch v1.3 by Larentis Mattia @spiritualGuru
 * http://www.larentis.eu/switch/
 * ============================================================
 * Licensed under the Apache License, Version 2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 * ============================================================ */
.has-switch {
	border-radius: 30px;
	display: inline-block;
	cursor: pointer;
	line-height: 1.72222;
	overflow: hidden;
	position: relative;
	text-align: left;
	width: 80px;
	-webkit-mask: url('../images/switch/mask.png') 0 0 no-repeat;
	mask: url('../images/switch/mask.png') 0 0 no-repeat;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}

.has-switch.deactivate {
	opacity: 0.5;
	filter: alpha(opacity=50);
	cursor: default !important;
}

.has-switch.deactivate label,
.has-switch.deactivate span {
	cursor: default !important;
}

.has-switch > div {
	width: 130px;
	position: relative;
	top: 0;
}

.has-switch > div.switch-animate {
	-webkit-transition: left 0.25s ease-out;
	transition: left 0.25s ease-out;
}

.has-switch > div.switch-off {
	left: -50px;
}

.has-switch > div.switch-off label {
	background-color: #7f8c9a;
	border-color: #bdc3c7;
	-webkit-box-shadow: -1px 0 0 rgba(255, 255, 255, 0.5);
	box-shadow: -1px 0 0 rgba(255, 255, 255, 0.5);
}

.has-switch > div.switch-on {
	left: 0;
}

.has-switch > div.switch-on label {
	background-color: #1abc9c;
}

.has-switch input[type=checkbox] {
	display: none;
}

.has-switch span {
	cursor: pointer;
	font-size: 15px;
	font-weight: 700;
	float: left;
	height: 29px;
	line-height: 19px;
	margin: 0;
	padding-bottom: 6px;
	padding-top: 5px;
	position: relative;
	text-align: center;
	width: 50%;
	z-index: 1;
	-webkit-transition: 0.25s ease-out;
	transition: 0.25s ease-out;
}

.has-switch span.switch-left {
	border-radius: 30px 0 0 30px;
	background-color: #34495e;
	color: #1abc9c;
	border-left: 1px solid transparent;
}

.has-switch span.switch-right {
	border-radius: 0 30px 30px 0;
	background-color: #bdc3c7;
	color: #ffffff;
	text-indent: 7px;
}

.has-switch span.switch-right [class*="fui-"] {
	text-indent: 0;
}

.has-switch label {
	border: 4px solid #34495e;
	border-radius: 50%;
	float: left;
	height: 29px;
	margin: 0 -15px;
	padding: 0;
	position: relative;
	vertical-align: middle;
	width: 29px;
	z-index: 100;
	-webkit-transition: 0.25s ease-out;
	transition: 0.25s ease-out;
}

.switch-square {
	border-radius: 6px;
	-webkit-mask: url('../images/switch/mask.png') 0 0 no-repeat;
	mask: url('../images/switch/mask.png') 0 0 no-repeat;
}

.switch-square > div.switch-off label {
	border-color: #7f8c9a;
	border-radius: 6px 0 0 6px;
}

.switch-square span.switch-left {
	border-radius: 6px 0 0 6px;
}

.switch-square span.switch-left [class*="fui-"] {
	text-indent: -10px;
}

.switch-square span.switch-right {
	border-radius: 0 6px 6px 0;
}

.switch-square span.switch-right [class*="fui-"] {
	text-indent: 5px;
}

.switch-square label {
	border-radius: 0 6px 6px 0;
	border-color: #1abc9c;
}

.share {
	background-color: #eff0f2;
	position: relative;
	border-radius: 6px;
}

.share:before {
	content: "";
	border-style: solid;
	border-width: 0 9px 9px 9px;
	border-color: transparent transparent #eff0f2 transparent;
	height: 0;
	position: absolute;
	left: 23px;
	top: -9px;
	width: 0;
	-webkit-transform: rotate(360deg);
}

.share ul {
	list-style-type: none;
	margin: 0;
	padding: 15px;
}

.share li {
	font-size: 15px;
	line-height: 1.4;
	padding-top: 11px;
}

.share li:before,
.share li:after {
	content: " ";
	/* 1 */

	display: table;
	/* 2 */

}

.share li:after {
	clear: both;
}

.share li:first-child {
	padding-top: 0;
}

.share .toggle {
	float: right;
	margin: 0;
}

.share .btn {
	border-radius: 0 0 6px 6px;
}

.share-label {
	float: left;
	font-size: 15px;
	line-height: 1.4;
	padding-top: 5px;
	width: 50%;
}

.pallete-item {
	width: 140px;
	float: left;
	margin: 0 0 20px 20px;
}

.palette {
	font-size: 14px;
	line-height: 1.214;
	color: #ffffff;
	margin: 0;
	padding: 15px;
	text-transform: uppercase;
}

.palette dt,
.palette dd {
	line-height: 1.429;
}

.palette dt {
	display: block;
	font-weight: bold;
	opacity: 0.8;
	filter: alpha(opacity=80);
}

.palette dd {
	font-weight: 300;
	margin-left: 0;
	opacity: 0.8;
	filter: alpha(opacity=80);
	-webkit-font-smoothing: subpixel-antialiased;
}

.palette-turquoise {
	background-color: #1abc9c;
}

.palette-green-sea {
	background-color: #16a085;
}

.palette-emerald {
	background-color: #2ecc71;
}

.palette-nephritis {
	background-color: #27ae60;
}

.palette-peter-river {
	background-color: #3498db;
}

.palette-belize-hole {
	background-color: #2980b9;
}

.palette-amethyst {
	background-color: #9b59b6;
}

.palette-wisteria {
	background-color: #8e44ad;
}

.palette-wet-asphalt {
	background-color: #34495e;
}

.palette-midnight-blue {
	background-color: #34495e;
}

.palette-sun-flower {
	background-color: #f1c40f;
}

.palette-orange {
	background-color: #f39c12;
}

.palette-carrot {
	background-color: #e67e22;
}

.palette-pumpkin {
	background-color: #d35400;
}

.palette-alizarin {
	background-color: #e74c3c;
}

.palette-pomegranate {
	background-color: #c0392b;
}

.palette-clouds {
	background-color: #ecf0f1;
}

.palette-silver {
	background-color: #bdc3c7;
}

.palette-concrete {
	background-color: #95a5a6;
}

.palette-asbestos {
	background-color: #7f8c8d;
}

.palette-clouds {
	color: #bdc3c7;
}

.palette-paragraph {
	color: #7f8c8d;
	font-size: 12px;
	line-height: 17px;
}

.palette-paragraph span {
	color: #bdc3c7;
}

.palette-headline {
	color: #7f8c8d;
	font-size: 13px;
	font-weight: 700;
	margin-top: -3px;
}

.tile {
	background-color: #eff0f2;
	border-radius: 6px;
	padding: 14px;
	position: relative;
	text-align: center;
}

.tile.tile-hot:before {
	background: url(../images/tile/ribbon.png) 0 0 no-repeat;
	background-size: 82px 82px;
	content: '';
	height: 82px;
	position: absolute;
	right: -4px;
	top: -4px;
	width: 82px;
}

.tile p {
	font-size: 15px;
	margin-bottom: 33px;
}

.tile-image {
	height: 100px;
	margin: 31px 0 27px;
	vertical-align: bottom;
}

.tile-image.big-illustration {
	height: 111px;
	margin-top: 20px;
	width: 112px;
}

.tile-title {
	font-size: 20px;
	margin: 0;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (-moz-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 3/2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (-moz-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 2) {
	.tile.tile-hot:before {
		background-image: url(../images/tile/ribbon-2x.png);
	}
}

.todo {
	color: #798795;
	margin-bottom: 20px;
	border-radius: 6px;
}

.todo ul {
	background-color: #34495e;
	margin: 0;
	padding: 0;
	list-style-type: none;
	border-radius: 0 0 6px 6px;
}

.todo li {
	background: #34495e url(../images/todo/todo.png) 92% center no-repeat;
	background-size: 20px 20px;
	cursor: pointer;
	font-size: 14px;
	line-height: 1.214;
	margin-top: 2px;
	padding: 18px 42px 21px 25px;
	position: relative;
	-webkit-transition: 0.25s;
	transition: 0.25s;
}

.todo li:first-child {
	margin-top: 0;
}

.todo li:last-child {
	border-radius: 0 0 6px 6px;
	padding-bottom: 21px;
}

.todo li.todo-done {
	background: transparent url(../images/todo/done.png) 92% center no-repeat;
	background-size: 20px 20px;
	color: #1abc9c;
}

.todo li.todo-done .todo-name {
	color: #1abc9c;
}

.todo-search {
	position: relative;
	background: #1abc9c;
	background-size: 16px 16px;
	border-radius: 6px 6px 0 0;
	color: #34495e;
	padding: 19px 25px 20px;
}

.todo-search:before {
	position: absolute;
	font-family: 'Flat-UI-Icons';
	content: "\e01c";
	font-size: 16px;
	line-height: 17px;
	display: inline-block;
	top: 50%;
	left: 92%;
	margin: -0.5em 0 0 -1em;
}

input.todo-search-field {
	background: none;
	border: none;
	color: #34495e;
	font-size: 19px;
	font-weight: 700;
	margin: 0;
	line-height: 23px;
	padding: 5px 0;
	text-indent: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}

input.todo-search-field:-moz-placeholder {
	color: #34495e;
}

input.todo-search-field::-moz-placeholder {
	color: #34495e;
	opacity: 1;
}

input.todo-search-field:-ms-input-placeholder {
	color: #34495e;
}

input.todo-search-field::-webkit-input-placeholder {
	color: #34495e;
}

input.todo-search-field.placeholder {
	color: #34495e;
}

.todo-icon {
	float: left;
	font-size: 24px;
	padding: 11px 22px 0 0;
}

.todo-content {
	padding-top: 1px;
	overflow: hidden;
}

.todo-name {
	color: #ffffff;
	font-size: 17px;
	margin: 1px 0 3px;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (-moz-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 3/2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (-moz-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 2) {
	.todo li {
		background-image: url(../images/todo/todo-2x.png);
	}

	.todo li.todo-done {
		background-image: url(../images/todo/done-2x.png);
	}
}

.video-js {
	background-color: transparent;
	margin-top: -95px;
	position: relative;
	padding: 0;
	font-size: 10px;
	vertical-align: middle;
	border-radius: 6px 6px 0 0;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	backface-visibility: hidden;
}

.video-js .vjs-tech {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 6px 6px 0 0;
}

.video-js:-moz-full-screen {
	position: absolute;
}

body.vjs-full-window {
	padding: 0;
	margin: 0;
	height: 100%;
	overflow-y: auto;
}

.video-js.vjs-fullscreen {
	position: fixed;
	overflow: hidden;
	z-index: 1000;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	width: 100% !important;
	height: 100% !important;
	_position: absolute;
}

.video-js:-webkit-full-screen {
	width: 100% !important;
	height: 100% !important;
}

.vjs-poster {
	margin: 0 auto;
	padding: 0;
	cursor: pointer;
	position: relative;
	width: 100%;
	max-height: 100%;
	border-radius: 6px 6px 0 0;
}

.video-js .vjs-text-track-display {
	text-align: center;
	position: absolute;
	bottom: 4em;
	left: 1em;
	right: 1em;
	font-family: "Lato", Helvetica, Arial, sans-serif;
}

.video-js .vjs-text-track {
	display: none;
	color: #ffffff;
	font-size: 1.4em;
	text-align: center;
	margin-bottom: .1em;
	background: #000;
	background: rgba(0, 0, 0, 0.5);
}

.video-js .vjs-subtitles {
	color: #fff;
}

.video-js .vjs-captions {
	color: #fc6;
}

.vjs-tt-cue {
	display: block;
}

.vjs-fade-in {
	visibility: visible !important;
	opacity: 1 !important;
	-webkit-transition: visibility 0s linear 0s, opacity .3s linear;
	transition: visibility 0s linear 0s, opacity .3s linear;
}

.vjs-fade-out {
	visibility: hidden !important;
	opacity: 0 !important;
	-webkit-transition: visibility 0s linear 1.5s, opacity 1.5s linear;
	transition: visibility 0s linear 1.5s, opacity 1.5s linear;
}

.vjs-control-bar {
	position: absolute;
	bottom: -47px;
	left: 0;
	right: 0;
	margin: 0;
	padding: 0;
	height: 47px;
	color: #ffffff;
	background: #273747;
	border-radius: 0 0 6px 6px;
}

.vjs-control-bar.vjs-fade-out {
	visibility: visible !important;
	opacity: 1 !important;
}

.vjs-control {
	background-position: center center;
	background-repeat: no-repeat;
	position: relative;
	float: left;
	text-align: center;
	margin: 0;
	padding: 0;
	height: 18px;
	width: 18px;
}

.vjs-control:focus {
	outline: 0;
}

.vjs-control div {
	background-position: center center;
	background-repeat: no-repeat;
}

.vjs-control-text {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.vjs-play-control {
	cursor: pointer !important;
	height: 47px;
	left: 0;
	position: absolute;
	top: 0;
	width: 58px;
}

.vjs-play-control div {
	position: relative;
	height: 47px;
}

.vjs-play-control div:before,
.vjs-play-control div:after {
	position: absolute;
	font-family: "Flat-UI-Icons";
	color: #1abc9c;
	font-size: 16px;
	top: 50%;
	left: 50%;
	margin: -0.55em 0 0 -0.5em;
	-webkit-transition: color .25s, opacity .25s;
	transition: color .25s, opacity .25s;
}

.vjs-play-control div:after {
	content: "\e03b";
}

.vjs-play-control div:before {
	content: "\e03c";
}

.vjs-paused .vjs-play-control:hover div:before {
	color: #16a085;
}

.vjs-paused .vjs-play-control div:after {
	opacity: 0;
	filter: alpha(opacity=0);
}

.vjs-paused .vjs-play-control div:before {
	opacity: 1;
	filter: alpha(opacity=100);
}

.vjs-playing .vjs-play-control:hover div:after {
	color: #16a085;
}

.vjs-playing .vjs-play-control div:after {
	opacity: 1;
	filter: alpha(opacity=100);
}

.vjs-playing .vjs-play-control div:before {
	opacity: 0;
	filter: alpha(opacity=0);
}

.vjs-rewind-control {
	width: 5em;
	cursor: pointer !important;
}

.vjs-rewind-control div {
	width: 19px;
	height: 16px;
	background: none transparent;
	margin: .5em auto 0;
}

.vjs-mute-control {
	background: url(../images/video/volume-full.png) center -48px no-repeat;
	background-size: 16px 64px;
	cursor: pointer !important;
	position: absolute;
	right: 51px;
	top: 14px;
}

.vjs-mute-control:hover div,
.vjs-mute-control:focus div {
	opacity: 0;
}

.vjs-mute-control.vjs-vol-0,
.vjs-mute-control.vjs-vol-0 div {
	background-image: url(../images/video/volume-off.png);
}

.vjs-mute-control div {
	background: #273747 url(../images/video/volume-full.png) no-repeat center 2px;
	background-size: 16px 64px;
	height: 18px;
	-webkit-transition: opacity 0.25s;
	transition: opacity 0.25s;
}

.vjs-volume-control,
.vjs-volume-level,
.vjs-volume-handle,
.vjs-volume-bar {
	display: none;
}

.vjs-progress-control {
	position: absolute;
	left: 60px;
	right: 180px;
	height: 12px;
	width: auto;
	top: 18px;
	background: #425669;
	border-radius: 32px;
}

.vjs-progress-holder {
	position: relative;
	cursor: pointer !important;
	padding: 0;
	margin: 0;
	height: 12px;
}

.vjs-play-progress,
.vjs-load-progress {
	position: absolute;
	display: block;
	height: 12px;
	margin: 0;
	padding: 0;
	left: 0;
	top: 0;
	border-radius: 32px;
}

.vjs-play-progress {
	background: #1abc9c;
	left: -1px;
}

.vjs-load-progress {
	background: #d6dbdf;
	border-radius: 32px 0 0 32px;
}

.vjs-load-progress[style*='100%'],
.vjs-load-progress[style*='99%'] {
	border-radius: 32px;
}

.vjs-seek-handle {
	background-color: #16a085;
	position: absolute;
	width: 18px;
	height: 18px;
	margin: -3px 0 0 1px;
	left: 0;
	top: 0;
	border-radius: 50%;
	-webkit-transition: background-color 0.25s;
	transition: background-color 0.25s;
}

.vjs-seek-handle[style*='95.'] {
	margin-left: 3px;
}

.vjs-seek-handle[style='left: 0%;'] {
	margin-left: -2px;
}

.vjs-seek-handle:hover,
.vjs-seek-handle:focus {
	background-color: #148d75;
}

.vjs-seek-handle:active {
	background-color: #117a65;
}

.vjs-time-controls {
	position: absolute;
	height: 20px;
	width: 50px;
	top: 16px;
	font: 300 13px "Lato", Helvetica, Arial, sans-serif;
}

.vjs-current-time {
	right: 128px;
	text-align: right;
}

.vjs-duration {
	color: #5d6d7e;
	right: 69px;
	text-align: left;
}

.vjs-remaining-time {
	display: none;
}

.vjs-time-divider {
	color: #5d6d7e;
	font-size: 14px;
	position: absolute;
	right: 121px;
	top: 15px;
}

.vjs-secondary-controls {
	float: right;
}

.vjs-fullscreen-control {
	background-image: url(../images/video/fullscreen.png);
	background-position: center -47px;
	background-size: 15px 64px;
	cursor: pointer !important;
	position: absolute;
	right: 17px;
	top: 13px;
}

.vjs-fullscreen-control:hover div,
.vjs-fullscreen-control:focus div {
	opacity: 0;
}

.vjs-fullscreen-control div {
	height: 18px;
	background: url(../images/video/fullscreen.png) no-repeat center 2px;
	background-size: 15px 64px;
	-webkit-transition: opacity 0.25s;
	transition: opacity 0.25s;
}

.vjs-menu-button {
	display: none !important;
}

@-webkit-keyframes sharp {
	0% {
		background: #e74c3c;
		border-radius: 10px;
		-webkit-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	50% {
		background: #ebedee;
		border-radius: 0;
		-webkit-transform: rotate(180deg);
		-ms-transform: rotate(180deg);
		transform: rotate(180deg);
	}
	100% {
		background: #e74c3c;
		border-radius: 10px;
		-webkit-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-moz-keyframes sharp {
	0% {
		background: #e74c3c;
		border-radius: 10px;
		-webkit-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	50% {
		background: #ebedee;
		border-radius: 0;
		-webkit-transform: rotate(180deg);
		-ms-transform: rotate(180deg);
		transform: rotate(180deg);
	}
	100% {
		background: #e74c3c;
		border-radius: 10px;
		-webkit-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-o-keyframes sharp {
	0% {
		background: #e74c3c;
		border-radius: 10px;
		-webkit-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	50% {
		background: #ebedee;
		border-radius: 0;
		-webkit-transform: rotate(180deg);
		-ms-transform: rotate(180deg);
		transform: rotate(180deg);
	}
	100% {
		background: #e74c3c;
		border-radius: 10px;
		-webkit-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes sharp {
	0% {
		background: #e74c3c;
		border-radius: 10px;
		-webkit-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	50% {
		background: #ebedee;
		border-radius: 0;
		-webkit-transform: rotate(180deg);
		-ms-transform: rotate(180deg);
		transform: rotate(180deg);
	}
	100% {
		background: #e74c3c;
		border-radius: 10px;
		-webkit-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.vjs-loading-spinner {
	background: #ebedee;
	display: none;
	height: 16px;
	left: 50%;
	margin: -8px 0 0 -8px;
	position: absolute;
	top: 50%;
	width: 16px;
	border-radius: 10px;
	-webkit-animation: sharp 2s ease infinite;
	animation: sharp 2s ease infinite;
}

.login {
	background: url(../images/login/imac.png) 0 0 no-repeat;
	background-size: 940px 778px;
	color: #ffffff;
	margin-bottom: 77px;
	padding: 38px 38px 267px;
	position: relative;
}

.login-screen {
	background-color: #1abc9c;
	min-height: 473px;
	padding: 123px 199px 33px 306px;
}

.login-icon {
	left: 200px;
	position: absolute;
	top: 160px;
	width: 96px;
}

.login-icon > img {
	display: block;
	margin-bottom: 6px;
	width: 100%;
}

.login-icon > h4 {
	font-size: 17px;
	font-weight: 300;
	line-height: 34px;
	opacity: 0.95;
	filter: alpha(opacity=95);
}

.login-icon > h4 small {
	color: inherit;
	display: block;
	font-size: inherit;
	font-weight: 700;
}

.login-form {
	background-color: #edeff1;
	padding: 24px 23px 20px;
	position: relative;
	border-radius: 6px;
}

.login-form:before {
	content: '';
	border-style: solid;
	border-width: 12px 12px 12px 0;
	border-color: transparent #edeff1 transparent transparent;
	height: 0;
	position: absolute;
	left: -12px;
	top: 35px;
	width: 0;
	-webkit-transform: rotate(360deg);
}

.login-form .control-group {
	margin-bottom: 6px;
	position: relative;
}

.login-form .login-field {
	border-color: transparent;
	font-size: 17px;
	text-indent: 3px;
}

.login-form .login-field:focus {
	border-color: #1abc9c;
}

.login-form .login-field:focus + .login-field-icon {
	color: #1abc9c;
}

.login-form .login-field-icon {
	color: #bfc9ca;
	font-size: 16px;
	position: absolute;
	right: 15px;
	top: 3px;
	-webkit-transition: 0.25s;
	transition: 0.25s;
}

.login-link {
	color: #bfc9ca;
	display: block;
	font-size: 13px;
	margin-top: 15px;
	text-align: center;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (-moz-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 3/2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (-moz-min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 2) {
	.login {
		background-image: url(../images/login/imac-2x.png);
	}
}

footer {
	background-color: #edeff1;
	color: #bac1c8;
	font-size: 15px;
	padding: 0;
}

footer a {
	color: #9aa4af;
	font-weight: 700;
}

footer p {
	font-size: 15px;
	line-height: 20px;
	margin-bottom: 10px;
}

.footer-title {
	margin: 0 0 8px;
	padding-top: 21px;
	font-size: 24px;
	line-height: 40px;
}

.footer-brand {
	display: block;
	margin-bottom: 26px;
	width: 220px;
}

.footer-brand img {
	width: 216px;
}

.footer-banner {
	background-color: #34495e;
	color: #fff;
	margin-left: 42px;
	min-height: 316px;
	padding: 0 30px 30px;
}

.footer-banner .footer-title {
	color: #ffffff;
}

.footer-banner a {
	color: #fff;
	text-decoration: underline;
}

.footer-banner a:hover {
	text-decoration: none;
}

.footer-banner ul {
	list-style-type: none;
	margin: 0 0 26px;
	padding: 0;
}

.footer-banner ul li {
	border-top: 0px solid #1bc5a3;
	line-height: 19px;
	padding: 6px 0;
}

.footer-banner ul li:first-child {
	border-top: none;
	padding-top: 1px;
}

.last-col {
	overflow: hidden;
}

.ptn,
.pvn,
.pan {
	padding-top: 0;
}

.ptx,
.pvx,
.pax {
	padding-top: 3px;
}

.pts,
.pvs,
.pas {
	padding-top: 5px;
}

.ptm,
.pvm,
.pam {
	padding-top: 10px;
}

.ptl,
.pvl,
.pal {
	padding-top: 20px;
}

.prn,
.phn,
.pan {
	padding-right: 0;
}

.prx,
.phx,
.pax {
	padding-right: 3px;
}

.prs,
.phs,
.pas {
	padding-right: 5px;
}

.prm,
.phm,
.pam {
	padding-right: 10px;
}

.prl,
.phl,
.pal {
	padding-right: 20px;
}

.pbn,
.pvn,
.pan {
	padding-bottom: 0;
}

.pbx,
.pvx,
.pax {
	padding-bottom: 3px;
}

.pbs,
.pvs,
.pas {
	padding-bottom: 5px;
}

.pbm,
.pvm,
.pam {
	padding-bottom: 10px;
}

.pbl,
.pvl,
.pal {
	padding-bottom: 20px;
}

.pln,
.phn,
.pan {
	padding-left: 0;
}

.plx,
.phx,
.pax {
	padding-left: 3px;
}

.pls,
.phs,
.pas {
	padding-left: 5px;
}

.plm,
.phm,
.pam {
	padding-left: 10px;
}

.pll,
.phl,
.pal {
	padding-left: 20px;
}

.mtn,
.mvn,
.man {
	margin-top: 0px;
}

.mtx,
.mvx,
.max {
	margin-top: 3px;
}

.mts,
.mvs,
.mas {
	margin-top: 5px;
}

.mtm,
.mvm,
.mam {
	margin-top: 10px;
}

.mtl,
.mvl,
.mal {
	margin-top: 20px;
}

.mrn,
.mhn,
.man {
	margin-right: 0px;
}

.mrx,
.mhx,
.max {
	margin-right: 3px;
}

.mrs,
.mhs,
.mas {
	margin-right: 5px;
}

.mrm,
.mhm,
.mam {
	margin-right: 10px;
}

.mrl,
.mhl,
.mal {
	margin-right: 20px;
}

.mbn,
.mvn,
.man {
	margin-bottom: 0px;
}

.mbx,
.mvx,
.max {
	margin-bottom: 3px;
}

.mbs,
.mvs,
.mas {
	margin-bottom: 5px;
}

.mbm,
.mvm,
.mam {
	margin-bottom: 10px;
}

.mbl,
.mvl,
.mal {
	margin-bottom: 20px;
}

.mln,
.mhn,
.man {
	margin-left: 0px;
}

.mlx,
.mhx,
.max {
	margin-left: 3px;
}

.mls,
.mhs,
.mas {
	margin-left: 5px;
}

.mlm,
.mhm,
.mam {
	margin-left: 10px;
}

.mll,
.mhl,
.mal {
	margin-left: 20px;
}

/* All Mobile Sizes (devices and browser) */
@media only screen and (max-width: 767px) {
	p {
		text-align: center;
	}

	h1, .h1 {
		font-size: 50px;
	}

	h3, .h3 {
		font-size: 20px;
		text-align: center
	}

	.col-sm-4 {
		margin-bottom: 120px;
		text-align: center;
	}

	.col-sm-4 > i {
		text-align: center;
	}

	.col-sm-5 .img-responsive {
		display: none
	}

	.col-sm-5 {
		margin-top: 120px
	}

	.section-heading {
		text-align: center
	}

	.lead {
		text-align: center
	}

	.box-icon {
		margin: 0 auto;
	}

	.social {
		text-align: center
	}

	.footer-banner {
		/*display: none*/
		margin: 0;
	}

	.navbar-nav > li > a {
		font-size: 22px;
		line-height: 42px;
		font-weight: 400;
		text-align: center;
	}

	.sub-title {
		text-align: center
	}

	.pull-right {
		margin-bottom: 40px;
	}

	address {
		margin-top: 130px;
	}

	.address {
		text-align: center
	}
}

@media only screen and (min-width: 768px) and (max-width: 959px) {

	h1, .h1 {
		font-size: 50px;
	}

	h3, .h3 {
		font-size: 20px;

	}

	.col-sm-4 {
		margin-bottom: 120px;
		text-align: center;
	}

	.col-sm-5 {
		margin-top: 120px
	}

	.box-ct {
		text-align: center
	}

	.box-icon {
		margin: 0 auto;
	}

	address {
		margin-top: 80px;
	}

	.address {
		text-align: center
	}

	.footer-banner {
		/*display: none*/
	}

}
