/*General */

body, html {
	height: 100%;
	width: 100%;
	overflow-x: hidden;
}

::-moz-selection {
	background: #1abc9c;
	color: white
}

::selection {
	background: #1abc9c;
	color: white
}

.abcen {
	position: absolute;
	bottom: 8%;
}

.abcen1 {
	position: absolute;
	top: 20%;
}

.content-section-c {
	border-top: 1px solid #32465A;
	border-bottom: 1px solid #32465A;
	background-color: #34495e;
	padding: 90px 0;
}

.descp {
	margin: 0;
	padding: 0;
	line-height: 40px !important;
}

.glyphicon {
	margin-right: 10px;
}

.lead2 {
	margin-bottom: 30px;
	margin-top: 20px;
	font-size: 18px;
	line-height: 1.46428571;
	font-weight: 300;
}

.lead3 {
	margin-bottom: 0px;
	margin-top: 0px;
	font-size: 16px;
	color: #8D8D8D;
	line-height: 1.46428571;
	font-weight: 300;
}

.float {
	-webkit-animation-name: floatingx;
	-webkit-animation-duration: 4s;
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-timing-function: ease-in-out;
	-moz-animation-name: floating;
	-moz-animation-duration: 4s;
	-moz-animation-iteration-count: infinite;
	-moz-animation-timing-function: ease-in-out;
}

.phone-image {
	max-width: 250px;
	margin: auto;
}

.h1_home {
	font-size: 70px;
	font-family: arvo;
	font-weight: 300;
	letter-spacing: 6px;
}

.h3_home {
	font-weight: 300;
}

/*own carousel */

#owl-demo .item {
	margin: 3px;
}

#owl-demo .item img {
	display: block;
	width: 100%;
	height: auto;
}

#owl-demo-1 .item {
	margin: 3px;
}

#owl-demo-1 .item img {
	display: block;
	width: 100%;
	height: auto;
}

/*magnific */
.mfp-bg {
	background: #000000 !important;
	opacity: 0.95 !important;
	filter: alpha(opacity=95) !important;
}

.item .img-responsive {
	cursor: url(../img/cross.png), auto;
}

/* Preloader */
#preloader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #fff; /* change if the mask should have another color then white */
	z-index: 99; /* makes sure it stays on top */
}

#status {
	width: 200px;
	height: 200px;
	position: absolute;
	left: 50%; /* centers the loading animation horizontally one the screen */
	top: 50%; /* centers the loading animation vertically one the screen */
	background-image: url(../img/status.gif); /* path to your loading animation */
	background-repeat: no-repeat;
	background-position: center;
	margin: -100px 0 0 -100px; /* is width and height divided by two */
}

.loading-animation {
	height: 70px;
	background-image: url(../img/status.gif); /* path to your loading animation */
	background-repeat: no-repeat;
	background-position: center;
	margin: auto; /* is width and height divided by two */
}

/* Button */
.mybutton_standard {
	border: 1px solid #fff;
	color: #fff;
}

.mybutton_cyano {
	border: 1px solid #4A90BE;
	color: #fff;
	background-color: #4A90BE;
}

.mybutton_cyano:hover {
	border: 1px solid #4A90BE;
	color: #4A90BE !important;
	background: rgba(204, 204, 204, 0);
}

.button_down img {
	width: 50px;
	border-radius: 50%;
	background-color: rgba(0, 0, 0, 0);
	transition: background-color 0.5s;
}
.button_down img:hover {
	background-color: rgba(0, 0, 0, 0.3);
}

.btn-primary {
	border-color: rgba(52, 73, 94, 0);
}

.tworow {
	margin-top: 90px;
}

.social {
	font-size: 30px;
	text-decoration: none;
	list-style-type: none;
	color: #1abc9c;
}

.social > a:hover {
	color: #1abc9c;
}

.box-icon {
	height: 150px;
	width: 150px;
	-moz-border-radius: 35px;
	border-radius: 100%;
	padding: 47px 10px;
	background-color: #f0f1ec;
	color: #034b81;
	text-align: center;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	margin-right: 15px;
}

.box-icon:hover {
	background-color: #1abc9c;
	color: #fff;
}

.owl-theme .owl-controls .owl-page span {
	background: #1abc9c !important;
}

/* Nav */
.navbar {
	position: relative;
	min-height: 60px;
	margin-bottom: 0;
	width: 100%;
	z-index: 999;
	border-radius: 0;
}

.navbar-default {
	position: relative;
	min-height: 60px;
	width: 100%;
	z-index: 999;

}

.navbar-default .navbar-nav > li > a {
	color: #ffffff;
}

.navbar-default .navbar-nav > li > a:hover {
	color: #ECEBEB;
}

.navbar-default .navbar-brand {
	color: #ffffff;
}

.navbar-default .navbar-brand:hover {
	color: #ECEBEB;
}

.navbar-brand {
	font-family: arvo;
	letter-spacing: 3px;
	font-weight: 400 !important;
}

.lead {
	font-size: 16px;
	font-weight: 400;
}

.intro-header {
	height: 100%; /* Full PAge */
	padding-top: 50px; /* If you're making other pages, make sure there is 50px of padding to make sure the navbar doesn't overlap content! */
	padding-bottom: 50px;
	color: #f8f8f8;
	background: url(../img/intro/intro5.jpg) no-repeat center center;
	background-size: cover;
	text-align: center;
}

.intro-message {
	position: relative;
	padding-top: 12%;
	padding-bottom: 20%;
}

.list-inline {
	margin-top: 30px;
}

.intro-message > h1 {
	margin: 0;
	font-size: 5em;
	font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-weight: 400;
	letter-spacing: 3px;
}

.intro-divider {
	width: 400px;
	border-top: 1px solid #f8f8f8;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.intro-message > h3 {
	margin: 0;
	font-size: 2.8em;
	font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-weight: 300;
	letter-spacing: 0px;
	margin-top: 10px;
}

.network-name {
	font-size: 14px;
	font-weight: 400;
	letter-spacing: 1.5px;
}

.content-section-a {
	background-color: #f8f8f8;
	padding: 90px 0;
}

.content-section-b {
	border-top: 1px solid #e7e7e7;
	border-bottom: 1px solid #e7e7e7;
	padding: 90px 0;
}

.section-heading {
	margin-bottom: 30px;
}

.section-heading-spacer {
	border-top: 3px solid #e7e7e7;
	width: 200px;
	float: left;
}

.banner-typo3 {
	height: 100%;
	padding: 100px 0;
	color: #f8f8f8;
	background: url(../img/emotional/sunrise-with-gulls.jpg) no-repeat center center;
	background-size: cover;
	text-shadow: 2px 2px 3px rgba(0,0,0,0.6);
	position: relative;

	.top {
		position: absolute;
		top: 33%;
		width: 100%;
	}

	.bottom {
		position: absolute;
		bottom: 15%;
		width: 100%;
	}
}

/* Animate */
.rotate {
	-webkit-transition-duration: 0.8s;
	-moz-transition-duration: 0.8s;
	-o-transition-duration: 0.8s;
	transition-duration: 0.8s;

	-webkit-transition-property: -webkit-transform;
	-moz-transition-property: -moz-transform;
	-o-transition-property: -o-transform;
	transition-property: transform;

	overflow: hidden;

}

.rotate:hover {
	-webkit-transform: rotate(360deg);
	-moz-transform: rotate(360deg);
	-o-transform: rotate(360deg);
}

/* Style for form modal */
.content-style-form {
	position: relative;
	text-align: left;
}

.content-style-form h2 {
	margin: 0;
	padding: 0.4em 0 0.3em;
	text-align: center;
	font-weight: 300;
	font-size: 3.5em;
}

.content-style-form form {
	padding: 10px 30px;
}

.content-style-form form p {
	margin: 0 0 5px 0;
	font-size: 0.7em;
}

.content-style-form label {
	display: block;
	padding: 10px 0 0;
	color: #34495e;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: bold;
}

.content-style-form input[type="text"],
.content-style-form input[type="password"] {
	padding: 10px;
	width: 100%;
	border: 2px solid #ebd3bd;
	background: transparent;
	color: #b09a86;
	font-weight: 300;
	font-size: 2.2em;
}

.content-style-form input[type="text"]:focus,
.content-style-form input[type="password"]:focus {
	border-color: #e75854;
	color: #e75854;
}

.content-style-form input:focus {
	outline: 0;
}

.content-style-form button {
	display: block;
	margin-top: 2.5em;
	padding: 1.5em;
	width: 100%;
	border: none;
	background: #e75854;
	color: #fff;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 800;
	font-size: 1.25em;
}

.content-style-form .icon-close {
	top: 0;
	right: 0;
	color: #ebd3bd;
	font-size: 75%;
}

.content-style-form .icon-close:hover {
	color: #e75854;
}

.js .content-style-form-1 h2,
.js .content-style-form-1 p,
.js .content-style-form-1 .icon-close {
	opacity: 0;
	-webkit-transition: opacity 0.2s 0.35s, -webkit-transform 0.2s 0.35s;
	transition: opacity 0.2s 0.35s, transform 0.2s 0.35s;
	-webkit-transform: scale(0.85);
	transform: scale(0.85);
}

.content-style-form-1 p:first-child {
	-webkit-transition-delay: 0.4s;
	transition-delay: 0.4s;
}

.content-style-form-1 p:nth-child(2) {
	-webkit-transition-delay: 0.45s;
	transition-delay: 0.45s;
}

.content-style-form-1 p:nth-child(3) {
	-webkit-transition-delay: 0.5s;
	transition-delay: 0.5s;
}

.morph-button.open .content-style-form-1 h2,
.morph-button.open .content-style-form-1 p,
.morph-button.open .content-style-form-1 .icon-close {
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
}

.js .content-style-form-2 h2,
.js .content-style-form-2 p,
.js .content-style-form-2 .icon-close {
	opacity: 0;
	-webkit-transition: opacity 0.2s 0.3s, -webkit-transform 0.2s 0.3s;
	transition: opacity 0.2s 0.3s, transform 0.2s 0.3s;
	-webkit-transform: translateY(50px);
	transform: translateY(50px);
}

.content-style-form-2 p:first-child {
	-webkit-transition-delay: 0.35s;
	transition-delay: 0.35s;
}

.content-style-form-2 p:nth-child(2) {
	-webkit-transition-delay: 0.4s;
	transition-delay: 0.4s;
}

.content-style-form-2 p:nth-child(3) {
	-webkit-transition-delay: 0.45s;
	transition-delay: 0.45s;
}

.content-style-form-2 p:nth-child(4) {
	-webkit-transition-delay: 0.5s;
	transition-delay: 0.5s;
}

.morph-button.open .content-style-form-2 h2,
.morph-button.open .content-style-form-2 p,
.morph-button.open .content-style-form-2 .icon-close {
	opacity: 1;
	-webkit-transform: translateY(0);
	transform: translateY(0);
}

.content-style-form-4 form {
	padding: 30px;
	background: #fff;
	color: #ccb096;
	font-size: 1.5em;
	-webkit-perspective: 1000px;
	perspective: 1000px;
}

.content-style-form-4 input[type="text"] {
	border: none;
	background-color: #f0f0f0;
}

.content-style-form-4 form button {
	background: #34495e;
}

.content-style-form-4 form button:focus,
.content-style-form-4 form button:hover {
	background: #1abc9c;
}

.js .content-style-form-4 p {
	opacity: 0;
	-webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
	transition: opacity 0.3s, transform 0.3s;
	-webkit-transform: rotateX(-45deg);
	transform: rotateX(-45deg);
	-webkit-transform-origin: center top;
	transform-origin: center top;
}

.morph-button.open .content-style-form-4 p {
	opacity: 1;
	-webkit-transition: opacity 0.4s 0.2s, -webkit-transform 0.4s 0.2s;
	transition: opacity 0.4s 0.2s, transform 0.4s 0.2s;
	-webkit-transform: rotateY(0deg);
	transform: rotateY(0deg);
}

.morph-button.open .content-style-form-4 p:nth-child(2) {
	-webkit-transition-delay: 0.35s;
	transition-delay: 0.35s;
}

.content-style-social {
	padding: 30px;
	text-align: left;
}

.morph-button-inflow-2 > button svg {
	display: inline-block;
	padding-right: 10px;
	width: 20px;
	height: 20px;
	vertical-align: -5%;
}

.morph-button-inflow-2 > button svg path {
	fill: #e75854;
}

.content-style-social a {
	display: block;
	padding: 0.5em 0;
	color: #67c2d4;
	vertical-align: middle;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 700;
	font-size: 0.8em;
	line-height: 32px;
}

.js .content-style-social a {
	-webkit-transition: -webkit-transform 0.3s;
	transition: transform 0.3s;
	-webkit-transform: translateX(100%);
	transform: translateX(100%);
}

.content-style-social a:hover {
	color: #e75854;
}

.content-style-social a:hover svg path {
	fill: #e75854;
}

.content-style-social a svg {
	display: inline-block;
	padding-right: 10px;
	vertical-align: middle;
	-webkit-backface-visibility: hidden;
}

.morph-button-inflow.open .content-style-social a {
	-webkit-transform: translateX(0);
	transform: translateX(0);
}

.morph-button-inflow.open .content-style-social a:nth-child(2) {
	-webkit-transition-delay: 0.05s;
	transition-delay: 0.05s;
}

.morph-button-inflow.open .content-style-social a:nth-child(3) {
	-webkit-transition-delay: 0.1s;
	transition-delay: 0.1s;
}

/* Morph Button: Default Styles */

.morph-button {
	position: relative;
	display: block;
	margin: 0 auto;
}

.morph-button > button {
	position: relative;
	padding: 0 1em;
	border: none;
	background-color: #e85657;
	color: #f9f6e5;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 700;
	line-height: 80px;
	overflow: hidden;
}

.morph-button.open > button {
	pointer-events: none;
}

.morph-content {
	pointer-events: none;
}

.morph-button.open .morph-content {
	pointer-events: auto;
}

/* Common styles for overlay and modal type (fixed morph) */
.morph-button-fixed,
.morph-button-fixed .morph-content {
	width: 300px;
	height: 80px;
}

.morph-button-fixed > button {
	z-index: 1000;
	width: 100%;
	height: 100%;
	-webkit-transition: opacity 0.1s 0.5s;
	transition: opacity 0.1s 0.5s;
}

.morph-button-fixed.open > button {
	opacity: 0;
	-webkit-transition: opacity 0.1s;
	transition: opacity 0.1s;
}

.morph-button-fixed .morph-content {
	position: fixed;
	z-index: 900;
	opacity: 0;
	-webkit-transition: opacity 0.3s 0.5s, width 0.4s 0.1s, height 0.4s 0.1s, top 0.4s 0.1s, left 0.4s 0.1s, margin 0.4s 0.1s;
	transition: opacity 0.3s 0.5s, width 0.4s 0.1s, height 0.4s 0.1s, top 0.4s 0.1s, left 0.4s 0.1s, margin 0.4s 0.1s;
}

.morph-button-fixed.open .morph-content {
	opacity: 1;
}

.morph-button-fixed .morph-content > div {
	visibility: hidden;
	height: 0;
	opacity: 0;
	-webkit-transition: opacity 0.1s, visibility 0s 0.1s, height 0s 0.1s;
	transition: opacity 0.1s, visibility 0s 0.1s, height 0s 0.1s;
}

.morph-button-fixed.open .morph-content > div {
	visibility: visible;
	height: auto;
	opacity: 1;
	-webkit-transition: opacity 0.3s 0.5s;
	transition: opacity 0.3s 0.5s;
}

.morph-button-fixed.active > button {
	z-index: 2000;
}

.morph-button-fixed.active .morph-content {
	z-index: 1900;
}

/* Transitions for overlay button and sidebar button */
.morph-button-overlay .morph-content,
.morph-button-sidebar .morph-content {
	-webkit-transition: opacity 0.3s 0.5s, width 0.4s 0.1s, height 0.4s 0.1s, top 0.4s 0.1s, left 0.4s 0.1s;
	transition: opacity 0.3s 0.5s, width 0.4s 0.1s, height 0.4s 0.1s, top 0.4s 0.1s, left 0.4s 0.1s;
}

.morph-button-overlay.open .morph-content,
.morph-button-sidebar.open .morph-content {
	-webkit-transition: width 0.4s 0.1s, height 0.4s 0.1s, top 0.4s 0.1s, left 0.4s 0.1s;
	transition: width 0.4s 0.1s, height 0.4s 0.1s, top 0.4s 0.1s, left 0.4s 0.1s;
}

/* Morph Button Style: Overlay */
.morph-button.morph-button-overlay {
	margin: 50px auto;
}

.morph-button-overlay .morph-content {
	overflow: hidden;
	background: #e85657;
}

.morph-button-overlay.open .morph-content {
	top: 0 !important;
	left: 0 !important;
	width: 100%;
	height: 100%;
}

/* Morph Button Style: Modal */
.morph-button-modal::before {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 800;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	content: '';
	opacity: 0;
	-webkit-transition: opacity 0.5s;
	transition: opacity 0.5s;
	pointer-events: none;
}

.morph-button-modal.open::before {
	opacity: 1;
	pointer-events: auto;
}

.morph-button-modal.active::before {
	z-index: 1800;
}

.morph-button-modal .morph-content {
	overflow: hidden;
	-webkit-transition: opacity 0.3s 0.5s, width 0.4s 0.1s, height 0.4s 0.1s, top 0.4s 0.1s, left 0.4s 0.1s, margin 0.4s 0.1s;
	transition: opacity 0.3s 0.5s, width 0.4s 0.1s, height 0.4s 0.1s, top 0.4s 0.1s, left 0.4s 0.1s, margin 0.4s 0.1s;
}

.morph-button-modal.open .morph-content {
	top: 50% !important;
	left: 50% !important;
	margin: -210px 0 0 -300px;
	width: 600px;
	height: 420px;
	-webkit-transition: width 0.4s 0.1s, height 0.4s 0.1s, top 0.4s 0.1s, left 0.4s 0.1s, margin 0.4s 0.1s;
	transition: width 0.4s 0.1s, height 0.4s 0.1s, top 0.4s 0.1s, left 0.4s 0.1s, margin 0.4s 0.1s;
}

/* Colors and sizes for individual modals */
.morph-button.morph-button-modal-1 {
	float: left;
}

.morph-button.morph-button-modal-2,
.morph-button.morph-button-modal-3 {
	display: inline-block;
	margin: 10px 15px;
}

.morph-button-modal-1 > button,
.morph-button-modal-1 .morph-content {
	background-color: #553445;
}

.morph-button-modal-2 > button,
.morph-button-modal-2 .morph-content,
.morph-button-modal-3 > button,
.morph-button-modal-3 .morph-content {
	background-color: #fef0e3;
	color: #e75854;
}

.morph-button-modal-4 {
	display: inline-block;
}

.morph-button-modal-4 > button,
.morph-button-modal-4 .morph-content {
	background-color: #faf1e0;
	color: #553445;
}

.morph-button-modal-4 > button span,
.morph-button-modal-4 .morph-clone {
	padding-left: 10px;
	color: #286f81;
}

.morph-button-modal-4 .morph-clone {
	position: absolute;
	right: 34px;
	bottom: 30px;
	z-index: 100;
	letter-spacing: 1px;
	font-weight: 700;
	-webkit-transition: bottom 0.4s 0.1s, right 0.4s 0.1s;
	transition: bottom 0.4s 0.1s, right 0.4s 0.1s;
}

.morph-button-modal-4.open .morph-clone,
.no-js .morph-button-modal-4 .morph-clone {
	right: 10px;
	bottom: 10px;
}

.morph-button-modal-1::before {
	background: rgba(240, 221, 204, 0.7);
}

.morph-button-modal-2.open .morph-content {
	margin: -210px 0 0 -170px;
	width: 340px;
	height: 420px;
}

.morph-button-modal-3.open .morph-content {
	margin: -255px 0 0 -210px;
	width: 420px;
	height: 510px;
}

.morph-button-modal-3.open .morph-content > div {
	height: 420px;
}

.morph-button-modal-2.open .morph-content > div,
.morph-button-modal-3.open .morph-content > div {
	-webkit-transition: opacity 0.3s 0.3s;
	transition: opacity 0.3s 0.3s;
}

.morph-button-modal-4.open .morph-content {
	margin: -200px 0 0 -320px;
	width: 640px;
	height: 400px;
}

/* Morph Button Style: In the content flow */
.morph-button-inflow {
	overflow: hidden;
	max-width: 100%;
	height: 70px;
}

.morph-button-inflow > button {
	width: 100%;
	line-height: 70px;
}

.morph-button-inflow .morph-content {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
}

.morph-button-inflow .morph-content .morph-clone {
	padding: 0;
	font-weight: 700;
	font-size: 1.5em;
	line-height: 70px;
}

/* Colors and sizes for individual in flow buttons */
.morph-button-inflow-1 {
	width: 600px;
	margin: 2em auto;
	-webkit-transition: height 0.5s cubic-bezier(0.7, 0, 0.3, 1);
	transition: height 0.5s cubic-bezier(0.7, 0, 0.3, 1);
}

.morph-button-inflow-1 > button span {
	visibility: hidden;
}

.morph-button-inflow-1 .morph-content .morph-clone {
	color: #FFFFFF;
	background: #1abc9c;
}

.morph-button-inflow-2 {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 220px;
	background-color: #fef0e3;
	-webkit-transition: height 0.3s, width 0.3s, -webkit-transform 0.3s;
	transition: height 0.3s, width 0.3s, transform 0.3s;
	-webkit-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
}

.morph-button-inflow-2 > button {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: transparent;
	color: #e75854;
	-webkit-transition: -webkit-transform 0.3s;
	transition: transform 0.3s;
}

.morph-button-inflow-2.open > button {
	-webkit-transform: translateX(-100%);
	transform: translateX(-100%);
}

.morph-button-inflow-2 .morph-content {
	width: 260px;
	height: 200px;
}

.morph-button-inflow-2.open {
	width: 260px;
}

/* Morph Button Style: Sidebar */
.morph-button-sidebar,
.morph-button-sidebar .morph-content {
	width: 60px;
	height: 60px;
}

.morph-button-sidebar {
	position: fixed;
	bottom: 50px;
	left: 50px;
}

.morph-button-sidebar > button {
	line-height: 60px;
	font-size: 1.6em;
	padding: 0;
}

.morph-button-sidebar .morph-content {
	background: #e85657;
}

.morph-button-sidebar.open .morph-content {
	top: 0 !important;
	left: 0 !important;
	width: 300px;
	height: 100%;
	overflow: hidden;
	-webkit-backface-visibility: hidden;
}

/* Let's add some nice easing for all cases */
.morph-button .morph-content,
.morph-button.open .morph-content,
.morph-button-modal-4 .morph-clone {
	-webkit-transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
	transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
}

/* Helper classes */
.noscroll {
	overflow: hidden;
}

.morph-button-overlay.scroll .morph-content {
	overflow-y: scroll;
}

.morph-button-sidebar.scroll .morph-content {
	overflow: auto;
}

/* No JS fallback: let's hide the button and show the content */
.no-js .morph-button > button {
	display: none;
}

.no-js .morph-button {
	margin: 10px 0;
	float: none;
}

.no-js .morph-button,
.no-js .morph-button .morph-content,
.no-js .morph-button .morph-content > div {
	position: relative;
	width: auto;
	height: auto;
	opacity: 1;
	visibility: visible;
	top: auto;
	left: auto;
	-webkit-transform: none;
	transform: none;
	pointer-events: auto;
}

.no-js .morph-button .morph-content .icon-close {
	display: none;
}

.no-js .morph-button-sidebar {
	width: 300px;
	position: fixed;
	top: 0;
	left: 0;
	margin: 0;
	height: 100%;
	background: #e85657;
	overflow: auto;
}

.no-transition {
	-webkit-transition: none !important;
	transition: none !important;
}

/* Media Queries */
/* Media queries!
-------------------------------------------------------------------------------*/

/* 960 Screens */
@media only screen and (min-width: 960px) and (max-width: 1250px) {

}

@media only screen and (min-width: 768px) and (max-width: 820px) {

}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 959px) {

	h1, .h1, .h1_home {
		font-size: 50px;
	}

	h3, .h3 {
		font-size: 20px;
	}

}

/* All Mobile Sizes (devices and browser) */
@media only screen and (max-width: 767px) {
	.intro-header {
		height: 100%;
		padding-top: 50px;
		padding-bottom: 50px;
		color: #f8f8f8;
		background-image: none;
		background-size: cover;
		text-align: center;
		background-color: #34495e;
	}

	.h1_home {
		font-size: 40px;
	}

	h1, .h1 {
		font-size: 50px;
	}

	h3, .h3 {
		font-size: 20px;
	}

	#gallery1 {
		display: none
	}

	.button_down {
		display: none
	}

}

/* Mobile Landscape */
@media only screen and (min-width: 480px) and (max-width: 767px) {

}

/* Mobile Portrait */
@media only screen and (max-width: 479px) {

}

@media screen and (max-width: 600px) {
	.h1_home {
		font-size: 25px;
	}

	.morph-button-modal.open .morph-content {
		top: 0% !important;
		left: 0% !important;
		margin: 0;
		width: 100%;
		height: 100%;
		overflow-y: scroll;
		-webkit-transition: width 0.4s 0.1s, height 0.4s 0.1s, top 0.4s 0.1s, left 0.4s 0.1s;
		transition: width 0.4s 0.1s, height 0.4s 0.1s, top 0.4s 0.1s, left 0.4s 0.1s;
	}
}

@media screen and (max-width: 400px) {
	.h1_home {
		font-size: 18px;
		margin-bottom: 50px;
	}

	.morph-button-fixed,
	.morph-button-fixed .morph-content {
		width: 200px;
		height: 80px;
	}

	.morph-button-fixed > button {
		font-size: 75%;
	}

	.morph-button-sidebar > button {
		font-size: 1.6em;
	}

	.morph-button-inflow .morph-content .morph-clone {
		font-size: 0.9em;
	}

	.morph-button-modal-4,
	.morph-button-modal-4 .morph-content {
		width: 220px;
		height: 120px;
	}

	.morph-button-modal-4 > button {
		font-size: 100%;
		line-height: 50px;
	}

	.morph-button-modal-4 > button span {
		display: block;
	}

	.morph-button-modal-4 .morph-clone {
		right: 83px;
		bottom: 26px;
	}

	.morph-button-sidebar,
	.morph-button-sidebar .morph-content {
		width: 100% !important;
		height: 60px !important;
	}

	.morph-button-sidebar {
		bottom: 0px;
		left: 0px;
	}

	.morph-button-sidebar.open .morph-content {
		height: 100% !important;
	}
}

@media screen and (max-width: 350px) {
	.h1_home {
		font-size: 13px;
		margin-bottom: 80px;
	}
}

@media (max-width: 1199px) {
	ul.banner-social-buttons {
		float: left;
		margin-top: 15px;
	}
}

@media (max-width: 767px) {
	.banner h2 {
		margin: 0;
		text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.6);
		font-size: 3em;
	}

	ul.banner-social-buttons > li {
		display: block;
		margin-bottom: 20px;
		padding: 0;
	}

	ul.banner-social-buttons > li:last-child {
		margin-bottom: 0;
	}
}

footer {
	background-color: #f8f8f8;
	padding: 50px 0;
}

p.copyright {
	margin: 15px 0 0;
}

@media (max-width: 767px) {
	.intro-message {
		padding-bottom: 15%;
	}

	.intro-message > h1 {
		font-size: 3em;
	}

	ul.intro-social-buttons > li {
		display: block;
		margin-bottom: 20px;
		padding: 0;
	}

	ul.intro-social-buttons > li:last-child {
		margin-bottom: 0;
	}

	.intro-divider {
		width: 100%;
	}
}

@media screen and (max-width: 770px) {
	.mockup-left {
		float: none;
		margin: 0 auto 20px;
	}

	.mockup-right {

	}

	.joiner {
		display: block;
	}
}

@media screen and (max-width: 400px) {
	.mockup-content > span:nth-last-child(2) {
		width: calc(100% - 210px);
	}
}

@media screen and (max-width: 25em) {

	.codrops-icon span {
		display: none;
	}

}
