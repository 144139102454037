$fa-font-path: "../font-awesome/fonts";
@import "../font-awesome/scss/font-awesome";

.margin-top-40 {
  margin-top: 40px;
}

.typo3-check-loading-wrapper {
  margin-top: 80px;
}

ul.list {
  margin: 15px 0;
  text-align: left;

  li {
    list-style: disc;
  }
}

.alert .list {
  max-width: 400px;
  margin: 15px auto;
}

#services .panel-body {
  min-height: 145px;
  a {
    color: #18bc9c;
    font-weight: bold;
    &:hover {
      text-decoration: underline;
    }
  }
}
